import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Heading from 'components/heading';

import cn from 'classnames';

const themes = {
  big: 'big',
  columns: 'columns'
};

const LinkList = ({ items, themes, title }) => {
  return (
    <div
      className={cn(
        'link-list',
        themes.map(theme => `link-list--${theme}`)
      )}
    >
      {title && (
        <Heading theme={Heading.themes.h5} color={Heading.colors.slate}>
          {title}
        </Heading>
      )}

      <ul>
        {items.map(item => (
          <li key={item.url + item.text}>
            <Link animation="shrink" {...item}></Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

LinkList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes))),
  title: PropTypes.string
};

LinkList.propTypesMeta = {
  themes: 'exclude'
};

LinkList.defaultProps = {
  items: [],
  themes: []
};

LinkList.themes = themes;

export default LinkList;
