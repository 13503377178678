import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';
import Image from 'components/image';
import Link from 'components/link';

const youTubeMeta = '?rel=0&amp;modestbranding=1';

const MediaBlock = ({ youTubeLink, image, link, title, isInspoArticle }) => {
  return (
    <div className="media-block">
      <div className="media-block__content">
        {youTubeLink && (
          <div className="media-block__iframe">
            <iframe
              src={youTubeLink + youTubeMeta}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={title}
            ></iframe>
          </div>
        )}
        {image && !youTubeLink && (
          <Image className="media-block__image" {...image} />
        )}
        {title && (
          <Heading
            className="media-block__heading"
            level={2}
            theme={
              isInspoArticle ? Heading.themes.h3PortalBold : Heading.themes.h1
            }
          >
            {title}
          </Heading>
        )}
        {link && (
          <div className="media-block__link">
            <Link
              animation="shrink"
              fontTheme={Link.fontThemes.uppercaseCondensed}
              color={Link.colors.black}
              showIcon={true}
              {...link}
            ></Link>
          </div>
        )}
      </div>
    </div>
  );
};

MediaBlock.propTypes = {
  youTubeLink: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string.isRequired,
  isInspoArticle: PropTypes.bool
};

export default MediaBlock;
