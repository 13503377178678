import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ src, height, title, width }) => {
  return (
    <div className="iframe__container">
      <iframe
        src={src}
        title={title}
        className="iframe"
        style={{ height, width }}
        loading="lazy"
      />
    </div>
  );
};

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Iframe;
