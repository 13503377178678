import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from 'components/image';
import LoginLink from 'components/login-link';
import Link from 'components/link';

const colorThemes = {
  yellow: 'yellow',
  greenDark: 'green-dark',
  pink: 'pink',
  orange: 'orange'
};

const FrontpageHeader = ({
  title,
  secondaryTitle,
  link,
  image,
  loginLink,
  colorTheme
}) => {
  return (
    <div
      className={cn('frontpage-header', {
        [`frontpage-header--color-${colorTheme}`]: colorTheme
      })}
    >
      <div className="frontpage-header__left">
        <div className="frontpage-header__text-container">
          {title && <h1 className="frontpage-header__title">{title}</h1>}
          {secondaryTitle && (
            <h2 className="frontpage-header__title-secondary">
              {secondaryTitle}
            </h2>
          )}
          {link && (
            <Link
              {...link}
              className="frontpage-header__link"
              animation="shrink"
              showIcon={true}
              iconIsAfterText={true}
              color={Link.colors.yellow}
              theme={Link.themes.frontpageHeader}
            />
          )}
          {loginLink && (
            <div className="frontpage-header__login-link">
              <LoginLink key={loginLink.text} {...loginLink} />
            </div>
          )}
        </div>
      </div>
      <div className="frontpage-header__right">
        {image && <Image {...image} theme={Image.themes.background} />}
      </div>
    </div>
  );
};

FrontpageHeader.defaultProps = {
  title: '',
  links: [],
  colorThemes: colorThemes.greenDark
};

FrontpageHeader.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(Image.propTypes),
  loginLink: PropTypes.exact(LoginLink.propTypes),
  colorTheme: PropTypes.oneOf(Object.values(colorThemes))
};

export default FrontpageHeader;
