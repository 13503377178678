import React from 'react';
import PropTypes from 'prop-types';

const youTubeMeta = '?rel=0&amp;modestbranding=1';

const VideoListItem = ({ title, url }) => {
  return (
    <figure className="video-list-item">
      <iframe
        src={url + youTubeMeta}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
        className="video-list-item__iframe"
      />
      <figcaption className="video-list-item__caption">{title}</figcaption>
    </figure>
  );
};

VideoListItem.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

export default VideoListItem;
