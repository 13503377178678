import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Heading from 'components/heading';

const InspoRelatedArticles = ({ title, articles }) => {
  return (
    <div className="inspo-related-articles">
      {title && (
        <Heading className="inspo-related-articles__title" level={2}>
          {title}
        </Heading>
      )}
      <ul className="inspo-related-articles__list">
        {articles.map(article => (
          <li key={article.title} className="inspo-related-articles__item">
            <a href={article.url} className="inspo-related-articles__link">
              <div className="inspo-related-articles__content-container">
                {article.image && (
                  <div className="inspo-related-articles__image">
                    <Image theme={Image.themes.background} {...article.image} />
                  </div>
                )}

                <div className="inspo-related-articles__content">
                  <div className="inspo-related-articles__tag">
                    {article.tag}
                  </div>
                  <Heading
                    level={3}
                    className="inspo-related-articles__article-title"
                  >
                    <span className="inspo-related-articles__article-title-span">
                      {article.title}
                    </span>
                  </Heading>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

InspoRelatedArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.exact(Image.propTypes),
      url: PropTypes.string
    })
  ),
  title: PropTypes.string
};

InspoRelatedArticles.defaultProps = {
  articles: []
};

export default InspoRelatedArticles;
