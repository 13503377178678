import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import cn from 'classnames';

const types = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin'
};

const SocialMediaLink = ({ text, type, url, shouldOpenInNewTab }) => {
  return (
    <a
      aria-label={text}
      className={cn('social-media-link')}
      href={url}
      target={shouldOpenInNewTab ? '_blank' : null}
      rel={shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null}
    >
      <Icon className="social-media-link__icon" name={type} />
    </a>
  );
};

SocialMediaLink.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(types)),
  url: PropTypes.string.isRequired,
  shouldOpenInNewTab: PropTypes.bool
};

export default SocialMediaLink;
