import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';

import Layout from 'components/layout';
import Support from 'components/support';
import InspoHeader from 'components/inspo-header';
import ContentWrapper from 'components/content-wrapper';
import InspoArticleHeader from 'components/inspo-article-header';
import ComponentList from 'components/component-list';
import ComponentListItem from 'components/component-list-item';
import Icon from 'components/icon';
import Link from 'components/link';

import cn from 'classnames';
import FullWidthQuoteBlock from 'components/full-width-quote-block';
import ColumnImageBlock from 'components/column-image-block';
import CallToAction from 'components/call-to-action';

const InspoArticlePage = ({
  layout,
  inspoHeader,
  mediaBlocks,
  articleHeader,
  blocks,
  linkToListpage
}) => {
  const header = <InspoHeader isArticlePage {...inspoHeader} />;

  return (
    <Layout
      supportThemes={[Support.themes.fullWidth]}
      fullWidthChildren={header}
      {...layout}
    >
      <ContentWrapper
        className={cn('inspo-article-page__header', {
          'inspo-article-page__header inspo-article-page__header--has-media-blocks': mediaBlocks
        })}
      >
        <InspoArticleHeader {...articleHeader} />
      </ContentWrapper>
      {mediaBlocks && mediaBlocks.length === 0 ? null : (
        <ContentWrapper>
          <ComponentList
            {...mediaBlocks}
            {...mainContentDefaultOptions}
            additionalComponentProps={{
              FullWidthQuoteBlock: { theme: FullWidthQuoteBlock.themes.green }
            }}
            wrapperElementProps={{
              InspoFullWidthMediaBlock: {
                themes: [ComponentListItem.themes.noMargin]
              },
              FullWidthQuoteBlock: {
                themes: [
                  ComponentListItem.themes.wide,
                  ComponentListItem.themes.noMargin
                ]
              }
            }}
          />
        </ContentWrapper>
      )}

      <ContentWrapper>
        {blocks && (
          <ComponentList
            isInspoArticle
            {...blocks}
            {...mainContentDefaultOptions}
            additionalComponentProps={{
              ColumnImageBlock: {
                alignment: ColumnImageBlock.alignments.right
              },
              CallToAction: {
                alignment: CallToAction.alignments.right
              }
            }}
            wrapperElementProps={{
              RelatedArticlesCarousel: {
                themes: [ComponentListItem.themes.wide]
              },
              InspoRichTextWithSidebar: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              InspoBulletList: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              ColumnImageBlock: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              TwoImagesBlock: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              FullWidthQuoteBlock: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              CreditsBlock: {
                themes: [
                  ComponentListItem.themes.visibleOnScroll,
                  ComponentListItem.themes.noMargin
                ]
              },
              ImageWithCaption: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              Carousel: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              MediaBlock: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              },
              CallToAction: {
                themes: [ComponentListItem.themes.visibleOnScroll]
              }
            }}
          />
        )}
        <div className="inspo-article-page__link-container">
          {linkToListpage && (
            <a href={linkToListpage.url} className="inspo-article-page__link">
              <Icon
                className="inspo-article-page__link-icon"
                name="arrow"
              ></Icon>
              <span className="inspo-article-page__link-span">
                {linkToListpage.text}
              </span>
            </a>
          )}
        </div>
      </ContentWrapper>
    </Layout>
  );
};

InspoArticlePage.propTypes = {
  articleHeader: PropTypes.exact(InspoArticleHeader.propTypes),
  blocks: PropTypes.exact(ComponentList.propTypes),
  inspoHeader: PropTypes.exact(InspoHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  mediaBlocks: PropTypes.exact(ComponentList.propTypes),
  linkToListpage: PropTypes.exact(Link.propTypes)
};

export default InspoArticlePage;
