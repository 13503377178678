import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';
import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import ComponentList from 'components/component-list';
import ComponentListItem from 'components/component-list-item';
import ContentWrapper from 'components/content-wrapper';
import Columns from 'components/columns';
import Heading from 'components/heading';
import Icon from 'components/icon';
import Image from 'components/image';
import Link from 'components/link';
import Metadata from 'components/metadata';
import RichText from 'components/rich-text';
import Support from 'components/support';
import Layout from 'components/layout';
import Text from 'components/text';

const ResidencePage = ({
  address,
  blocks,
  callToAction,
  image,
  ingress,
  layout,
  logo,
  metadataGroups,
  price,
  showingLabel,
  showing,
  sidebar,
  squareMeters,
  status,
  title
}) => {
  const header = (
    <div className="residence-page__header">
      <ContentWrapper className="residence-page__header-text">
        <Heading level={1} theme={Heading.themes.h1}>
          {title}
        </Heading>
        <p className="residence-page__address">{address}</p>

        <p className="residence-page__price">{price}</p>
        <p className="residence-page__square-meters">{squareMeters}</p>
      </ContentWrapper>

      <div className="residence-page__image">
        <div className="residence-page__image-inner">
          <Image theme={Image.themes.background} {...image} />
        </div>
      </div>
    </div>
  );

  return (
    <Layout
      supportThemes={[Support.themes.fullWidth]}
      fullWidthChildren={header}
      {...layout}
    >
      <div className="residence-page">
        <Columns
          className="residence-page__intro"
          themes={[Columns.themes.wideContent, Columns.themes.compactMobile]}
        >
          <Columns.Main>
            <Text theme={Text.themes.ingress}>{ingress}</Text>
          </Columns.Main>
          <Columns.Sidebar>
            {logo && <Image className="residence-page__logo" {...logo} />}
          </Columns.Sidebar>
        </Columns>

        <Columns themes={[Columns.themes.wideContent]}>
          <Columns.Main>
            <div className="residence-page__status">{status}</div>

            {metadataGroups.map(group => (
              <Metadata
                className="residence-page__metadata"
                key={group.id}
                {...group}
              />
            ))}
            {showing && (
              <div className="residence-page__showing">
                <div className="residence-page__showing-title">
                  <Icon className="residence-page__clock-icon" name="clock" />
                  <Heading theme={Heading.themes.h6}>{showingLabel}</Heading>
                </div>
                <RichText
                  themes={[RichText.themes.compact, RichText.themes.biggerText]}
                  {...showing}
                />
              </div>
            )}
            {callToAction && (
              <div className="residence-page__cta">
                <Link
                  animation="expand"
                  theme={Link.themes.buttonSmall}
                  {...callToAction}
                ></Link>
              </div>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            {sidebar && (
              <ComponentList
                {...sidebar}
                {...sidebarDefaultOptions}
                wrapperElementProps={{
                  IconLinkList: {
                    themes: [ComponentListItem.themes.smallMargin]
                  },
                  Downloads: {
                    themes: [ComponentListItem.themes.smallMargin]
                  }
                }}
              />
            )}
          </Columns.Sidebar>
        </Columns>

        {blocks && (
          <ContentWrapper className="residence-page__blocks">
            <ComponentList {...blocks} {...mainContentDefaultOptions} />
          </ContentWrapper>
        )}
      </div>
    </Layout>
  );
};

ResidencePage.propTypes = {
  address: PropTypes.string,
  blocks: PropTypes.exact(ComponentList.propTypes),
  callToAction: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(Image.propTypes).isRequired,
  ingress: PropTypes.string,
  layout: PropTypes.exact(Layout.propTypes),
  logo: PropTypes.exact(Image.propTypes),
  metadataGroups: PropTypes.arrayOf(PropTypes.exact(Metadata.propTypes)),
  price: PropTypes.string,
  showingLabel: PropTypes.string,
  showing: PropTypes.exact(RichText.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes),
  squareMeters: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string
};

ResidencePage.defaultProps = {
  metadataGroups: []
};

export default ResidencePage;
