import React from 'react';
import PropTypes from 'prop-types';

import EventListItem from './event-list-item';

const EventList = ({ columnLabels, events }) => {
  const columnIds = React.useMemo(
    () => columnLabels.map(label => `event-list-label-${label}`),
    [columnLabels]
  );
  const cellWidth = React.useMemo(() => `${(1 / columnLabels.length) * 100}%`, [
    columnLabels
  ]);

  return (
    <div className="event-list">
      {columnLabels.map((label, index) => (
        <div
          className="event-list__label"
          id={columnIds[index]}
          key={label}
          style={{ width: cellWidth }}
        >
          {label}
        </div>
      ))}
      {events.map(event => (
        <EventListItem
          columnIds={columnIds}
          key={event.texts.join('')}
          width={cellWidth}
          {...event}
        />
      ))}
    </div>
  );
};

EventList.propTypes = {
  columnLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  events: PropTypes.arrayOf(PropTypes.exact(EventListItem.propTypes))
};

EventList.defaultProps = {
  columnLabels: [],
  events: []
};

export default EventList;
