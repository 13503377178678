import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useRelativeMousePosition from 'hooks/use-relative-mouse-position';

import Icon from 'components/icon';

const themes = {
  next: 'next',
  previous: 'previous'
};

const CarouselButton = ({ className, isActive, onClick, text, theme }) => {
  const [onMouseMove, mouseX, mouseY] = useRelativeMousePosition();

  return (
    <button
      aria-label={text}
      className={cn('carousel-button', className, {
        [`carousel-button--${theme}`]: theme
      })}
      disabled={!isActive}
      onClick={onClick}
      onMouseMove={onMouseMove}
      type="button"
    >
      <Icon
        className="carousel-button__icon"
        name="chevron-big-right"
        style={{ left: mouseX, top: mouseY }}
      />
    </button>
  );
};

CarouselButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

CarouselButton.propTypesMeta = 'exclude';

CarouselButton.themes = themes;

export default CarouselButton;
