import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

const EventListItem = ({ columnIds, filterIds, texts, url, width }) => {
  const { shouldShow } = React.useContext(FilterContext);

  return !shouldShow(filterIds) ? null : (
    <a className="event-list__item" href={url}>
      {texts.map((text, index) => (
        <span
          aria-labelledby={columnIds[index]}
          className="event-list__field"
          key={text}
          style={{ width }}
        >
          {text}
        </span>
      ))}
    </a>
  );
};

EventListItem.propTypes = {
  columnIds: PropTypes.arrayOf(PropTypes.string),
  filterIds: PropTypes.arrayOf(PropTypes.string),
  texts: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
  width: PropTypes.string
};

EventListItem.propTypesMeta = {
  columnIds: 'exclude',
  width: 'exclude'
};

EventListItem.defaultProps = {
  filterIds: [],
  texts: []
};

export default EventListItem;
