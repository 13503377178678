import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from '../../js/api-helper';
import useGaTracking from 'hooks/use-ga-tracking';

import ContentWrapper from 'components/content-wrapper';
import TextInput from 'components/text-input';
import Button from 'components/button';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import Checkbox from '../checkbox/checkbox';
import RichText from '../rich-text/rich-text';

const HousingProjectContactForm = ({
  title,
  description,
  nameInput,
  postNumberInput,
  emailInput,
  phoneInput,
  checkbox,
  submitButtonText,
  submitButtonSuccessText,
  errorText,
  endpoint,
  theme,
  trackingEndpoint,
  trackingReferer
}) => {
  const [nameTextInput, setNameTextInput] = React.useState('');
  const [postNumberTextInput, setPostNumberTextInput] = React.useState('');
  const [emailTextInput, setEmailTextInput] = React.useState('');
  const [phoneTextInput, setPhoneTextInput] = React.useState('');
  const [isCheckBoxChecked, setIsCheckBoxChecked] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isFormSent, setIsFormSent] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);

  const nameInputRef = React.useRef();
  const postNumberInputRef = React.useRef();
  const emailInputRef = React.useRef();
  const phoneInputRef = React.useRef();

  const textInputs = {
    name: nameTextInput,
    postNumber: postNumberTextInput,
    email: emailTextInput,
    phone: phoneTextInput
  };

  const [inputError, setInputError] = React.useState({
    nameIsEmpty: false,
    postNumberIsEmpty: false,
    postNumberIsNotValid: false,
    emailIsEmpty: false,
    emailIsNotValid: false
  });

  React.useEffect(() => {
    let hasError = Object.values(inputError).includes(true);
    if (showErrorMessage) {
      setShowErrorMessage(hasError);
    }
  }, [inputError]);

  const checkIfEmailValid = value => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  const checkIfPostNumberValid = value => {
    return /^\d{4}$/.test(value);
  };

  const handleInputChange = e => {
    const input = e.target;
    const inputValue = input.value;

    if (input === nameInputRef.current) {
      setNameTextInput(inputValue);
      setInputError({
        ...inputError,
        nameIsEmpty: inputValue === '' ? true : false
      });
    }

    if (input === emailInputRef.current) {
      setEmailTextInput(inputValue);
      setInputError({
        ...inputError,
        emailIsEmpty: inputValue === '' ? true : false,
        emailIsNotValid: checkIfEmailValid(inputValue) ? false : true
      });
    }

    if (input === postNumberInputRef.current) {
      setPostNumberTextInput(inputValue);
      setInputError({
        ...inputError,
        postNumberIsEmpty: inputValue === '' ? true : false,
        postNumberIsNotValid: checkIfPostNumberValid(inputValue) ? false : true
      });
    }

    if (input === phoneInputRef.current) {
      setPhoneTextInput(inputValue);
    }
  };

  const trackResults = () => {
    api.execute(trackingEndpoint, {
      phoneNumber: phoneTextInput,
      email: emailTextInput,
      fullName: nameTextInput,
      referer: trackingReferer
    });
  };

  const trackResultsGTM = () => {
    useGaTracking('HousingProjectContactForm', 'Form submit', title);
  };

  const onSubmit = () => {
    const emailIsValid = checkIfEmailValid(emailTextInput);
    const postNumberIsValid = checkIfPostNumberValid(postNumberTextInput);

    setIsLoading(true);
    if (
      nameTextInput !== '' &&
      emailIsValid &&
      emailTextInput !== '' &&
      postNumberTextInput !== '' &&
      postNumberIsValid
    ) {
      api
        .execute(endpoint, {
          textInputs: textInputs
        })
        .then(response => {
          setIsLoading(false);
          if (response.success) {
            setIsFormSent(true);
            setShowSuccessMessage(true);
            setShowErrorMessage(false);

            trackResults();
            trackResultsGTM();
          } else {
            setShowErrorMessage(true);
          }
        });
    } else {
      setIsLoading(false);
      setShowErrorMessage(true);
      setInputError({
        ...inputError,
        nameIsEmpty:
          nameTextInput === null || nameTextInput === '' ? true : false,
        emailIsEmpty:
          emailTextInput === null || emailTextInput === '' ? true : false,
        emailIsNotValid: emailIsValid ? false : true,
        postNumberIsEmpty: postNumberTextInput === '' ? true : false,
        postNumberIsNotValid: postNumberIsValid ? false : true
      });
    }
  };

  return (
    <div
      className={cn('housing-project-contact-form', {
        [`housing-project-contact-form--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader title={title} description={description} />
        <div className="housing-project-contact-form__inputs">
          {nameInput && (
            <TextInput
              className={cn('text-input--in-housing-project-contact-form', {
                [`text-input--in-housing-project-contact-form--${theme}`]: theme
              })}
              ref={nameInputRef}
              onChange={handleInputChange}
              error={inputError && inputError.nameIsEmpty}
              {...nameInput}
            />
          )}

          {postNumberInput && (
            <TextInput
              className={cn('text-input--in-housing-project-contact-form', {
                [`text-input--in-housing-project-contact-form--${theme}`]: theme
              })}
              ref={postNumberInputRef}
              onChange={handleInputChange}
              error={
                (inputError && inputError.postNumberIsEmpty) ||
                (inputError && inputError.postNumberIsNotValid)
              }
              type="number"
              {...postNumberInput}
            />
          )}

          {emailInput && (
            <TextInput
              className={cn('text-input--in-housing-project-contact-form', {
                [`text-input--in-housing-project-contact-form--${theme}`]: theme
              })}
              ref={emailInputRef}
              onChange={handleInputChange}
              error={
                (inputError && inputError.emailIsEmpty) ||
                (inputError && inputError.emailIsNotValid)
              }
              {...emailInput}
            />
          )}
          {phoneInput && (
            <TextInput
              className={cn('text-input--in-housing-project-contact-form', {
                [`text-input--in-housing-project-contact-form--${theme}`]: theme
              })}
              ref={phoneInputRef}
              onChange={handleInputChange}
              {...phoneInput}
            />
          )}

          {showErrorMessage && (
            <div className="housing-project-contact-form__error-text">
              {errorText}
            </div>
          )}
          {checkbox && checkbox.html && (
            <Checkbox
              checked={isCheckBoxChecked}
              onChange={setIsCheckBoxChecked}
              className="housing-project-contact-form__checkbox"
            >
              <RichText
                themes={[RichText.themes.housingProjectContactForm]}
                {...checkbox}
              />
            </Checkbox>
          )}
          {submitButtonText && (
            <div className="housing-project-contact-form__button-container">
              <Button
                className="housing-project-contact-form__button"
                housingProjectBaseTheme={
                  theme === 'green'
                    ? 'dark'
                    : theme === 'dark'
                    ? 'dark'
                    : 'light'
                }
                housingProjectColorTheme={theme === 'green' ? theme : null}
                housingProjectButtonTheme="primary"
                onClick={e => {
                  e.preventDefault();
                  onSubmit();
                }}
                text={
                  showSuccessMessage
                    ? submitButtonSuccessText
                    : submitButtonText
                }
                success={isFormSent}
                loading={isLoading}
                disabled={showSuccessMessage || !isCheckBoxChecked}
              />
            </div>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectContactForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  nameInput: PropTypes.exact(TextInput.propTypes),
  postNumberInput: PropTypes.exact(TextInput.propTypes),
  emailInput: PropTypes.exact(TextInput.propTypes),
  phoneInput: PropTypes.exact(TextInput.propTypes),
  checkbox: PropTypes.shape({ html: PropTypes.string }),
  submitButtonText: PropTypes.string,
  submitButtonSuccessText: PropTypes.string,
  errorText: PropTypes.string,
  endpoint: PropTypes.string,
  theme: PropTypes.string,
  trackingEndpoint: PropTypes.string.isRequired,
  trackingReferer: PropTypes.string.isRequired
};

HousingProjectContactForm.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectContactForm;
