import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  background: 'background',
  stretch: 'stretch'
};

const Image = ({ alt, className, src, width, theme, blendMode }) => {
  const image = (
    <img
      alt={alt ? alt : ''}
      className={cn('image', { [`image--${theme}`]: theme }, className)}
      src={src}
      {...(width !== 0 ? { style: { width } } : {})} // only styles width for .svg images
    />
  );

  return theme === themes.background ? (
    <div
      className={cn('image__background-wrapper', {
        'image__background-wrapper--blend-mode': blendMode
      })}
      style={{ backgroundImage: `url(${src})` }}
    >
      {image}
    </div>
  ) : (
    image
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  theme: PropTypes.oneOf(Object.values(themes)),
  blendMode: PropTypes.bool
};

Image.propTypesMeta = {
  className: 'exclude',
  theme: 'exclude',
  blendMode: 'exclude'
};

Image.themes = themes;

export default Image;
