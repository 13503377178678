import React from 'react';
import PropTypes from 'prop-types';

import ContactInfo from 'components/contact-info';
import Drawer from 'components/drawer';
import Heading from 'components/heading';
import LinkList from 'components/link-list';
import CallToAction from 'components/call-to-action';

const SupportLinks = ({
  callToAction,
  contactInfo,
  hide,
  hideText,
  linkLists,
  text,
  title
}) => {
  return (
    <Drawer hide={hide} hideText={hideText}>
      <div className="support-links">
        <Heading>{title}</Heading>

        <div className="support-links__links">
          {linkLists.map(list => (
            <div className="support-links__link-list" key={list.title}>
              <LinkList {...list} />
            </div>
          ))}
        </div>

        {text && <div className="support-links__text">{text}</div>}
        {callToAction && (
          <div className="support-links__cta">
            <CallToAction {...callToAction} theme="button" />
          </div>
        )}
      </div>

      {contactInfo && (
        <ContactInfo theme={ContactInfo.themes.beige} {...contactInfo} />
      )}
    </Drawer>
  );
};

SupportLinks.propTypes = {
  callToAction: PropTypes.exact(CallToAction.propTypes),
  contactInfo: PropTypes.exact(ContactInfo.propTypes),
  hide: PropTypes.func,
  hideText: PropTypes.string.isRequired,
  linkLists: PropTypes.arrayOf(PropTypes.exact(LinkList.propTypes)),
  text: PropTypes.string,
  title: PropTypes.string
};

SupportLinks.defaultProps = {
  linkLists: []
};

export default SupportLinks;
