import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Image from 'components/image';
import Heading from 'components/heading';
import RichText from 'components/rich-text';
import cn from 'classnames';

const themes = {
  red: 'red',
  yellow: 'yellow',
  green: 'green'
};

const CarouselTeaserBlock = ({
  title,
  textHtml,
  theme,
  image,
  link,
  className
}) => (
  <article
    className={cn('carousel-teaser-block', className, {
      [`carousel-teaser-block--${theme}`]: theme,
      'carousel-teaser-block--no-image': !image
    })}
  >
    {image && (
      <div className="carousel-teaser-block__image-container">
        <Image {...image} theme={Image.themes.background} />
      </div>
    )}
    <div className="carousel-teaser-block__text-container">
      {title && (
        <Heading
          className="carousel-teaser-block__title"
          level={2}
          theme={Heading.themes.h2}
        >
          {title}
        </Heading>
      )}
      {textHtml && (
        <RichText
          {...textHtml}
          className="carousel-teaser-block__text rich-text--in-carousel-teaser"
        />
      )}
      {link && (
        <a className="carousel-teaser-block__link" href={link.url}>
          <span className="carousel-teaser-block__link-text">{link.text}</span>
        </a>
      )}
    </div>
  </article>
);

CarouselTeaserBlock.propTypes = {
  title: PropTypes.string,
  textHtml: PropTypes.exact(RichText.propTypes),
  image: PropTypes.exact(Image.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  link: PropTypes.exact(Link.propTypes),
  className: PropTypes.string
};

CarouselTeaserBlock.propTypesMeta = {
  theme: 'exclude',
  className: 'exclude'
};

export default CarouselTeaserBlock;
