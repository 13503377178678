import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import Image from 'components/image';
import LinkList from 'components/link-list';
import Text from 'components/text';

const themes = {
  smallText: 'small-text',
  verticalMobile: 'vertical-mobile'
};

const ContactCard = ({
  contact,
  description,
  image,
  jobTitle,
  name,
  themes
}) => {
  return (
    <div
      className={cn(
        'contact-card',
        themes.map(theme => `contact-card--${theme}`)
      )}
    >
      <div className="contact-card__text">
        <div className="contact-card__header">
          <Heading
            className="contact-card__title"
            theme={
              themes.includes(themes.smallText) ? undefined : Heading.themes.h2
            }
          >
            {name}
          </Heading>

          {jobTitle && <p className="contact-card__job-title">{jobTitle}</p>}
        </div>

        {description && (
          <Text theme={Text.themes.ingressSmall}>{description}</Text>
        )}
        {contact && <LinkList {...contact} />}
      </div>

      {/* NOTE: The heading needs to be the first element of the block. The image is put visually as the first element with CSS */}
      {image && (
        <div className="contact-card__image">
          <Image theme={Image.themes.background} {...image} />
        </div>
      )}
    </div>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.exact(LinkList.propTypes),
  description: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  jobTitle: PropTypes.string,
  name: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
};

ContactCard.propTypesMeta = {
  themes: 'exclude'
};

ContactCard.defaultProps = {
  themes: []
};

ContactCard.themes = themes;

export default ContactCard;
