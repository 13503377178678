import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  orange: 'orange',
  blue: 'blue',
  pink: 'pink'
};

const alignments = {
  right: 'right',
  rightSmall: 'right-small',
  leftSmall: 'left-small'
};

const CornerWrapper = ({ children, theme, align }) => {
  return (
    <div
      className={cn('corner-wrapper', {
        [`corner-wrapper--${theme}`]: theme,
        [`corner-wrapper--${align}`]: align
      })}
    >
      {children}
    </div>
  );
};

CornerWrapper.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(Object.values(themes)),
  align: PropTypes.oneOf(Object.values(alignments))
};

CornerWrapper.propTypesMeta = 'exclude';

CornerWrapper.themes = themes;
CornerWrapper.alignments = alignments;

export default CornerWrapper;
