import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import cn from 'classnames';

const colors = {
  black: 'black',
  green: 'green',
  greenDark: 'green-dark',
  yellow: 'yellow',
  slate: 'slate',
  white: 'white',
  yellowOnMobile: 'yellow-on-mobile',
  redDark: 'red-dark'
};

const fontThemes = {
  light: 'light',
  regular: 'regular',
  regularSmall: 'regular-small',
  uppercaseCondensed: 'uppercase-condensed',
  uppercase: 'uppercase',
  bold: 'bold'
};

const animation = {
  expand: 'expand',
  shrink: 'shrink'
};

const themes = {
  buttonSmall: 'button-small',
  menu: 'menu',
  loginLinkYellow: 'login-link-yellow',
  loginLinkBlack: 'login-link-black',
  inspoHeader: 'inspo-header',
  frontpageHeader: 'frontpage-header'
};

const Link = ({
  color,
  animation,
  fontTheme,
  text,
  url,
  iconName,
  showIcon,
  theme,
  shouldOpenInNewTab,
  className,
  onClick,
  isExternalLink,
  iconIsAfterText,
  isAnchorLink,
  ariaCurrent
}) => (
  <a
    className={cn('link', {
      [`${className}`]: className,
      [`link--animation-${animation}`]: animation,
      [`link--color-${color}`]: color,
      [`link--font-theme-${fontTheme}`]: fontTheme,
      [`link--theme-${theme}`]: theme,
      'link--has-icon': showIcon,
      'link--is-external': isExternalLink || isAnchorLink,
      'link--is-current': ariaCurrent
    })}
    onClick={onClick ? onClick : null}
    href={url}
    target={shouldOpenInNewTab ? '_blank' : null}
    rel={shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null}
    aria-current={ariaCurrent || 'undefined'}
  >
    {showIcon && !iconIsAfterText && (
      <span className="link__icon-container">
        <Icon name={iconName} className="link__icon" />
      </span>
    )}
    <div className="link__text-wrapper">
      <span className="link__text">{text}</span>
    </div>
    {showIcon && iconIsAfterText && (
      <span className="link__icon-container">
        <Icon name={iconName} className="link__icon" />
      </span>
    )}
    {isExternalLink && (
      <Icon
        name="external-link-extra-small"
        className="link__external-link-icon"
      />
    )}
    {isAnchorLink && (
      <Icon name="arrow-downward" className="link__anchor-link-icon" />
    )}
  </a>
);

Link.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(colors)),
  animation: PropTypes.oneOf(Object.values(animation)),
  fontTheme: PropTypes.oneOf(Object.values(fontThemes)),
  iconName: PropTypes.string,
  showIcon: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes)),
  shouldOpenInNewTab: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isExternalLink: PropTypes.bool,
  iconIsAfterText: PropTypes.bool,
  ariaCurrent: PropTypes.bool,
  isAnchorLink: PropTypes.bool
};

Link.propTypesMeta = {
  iconName: 'exclude',
  animation: 'exclude',
  color: 'exclude',
  fontTheme: 'exclude',
  showIcon: 'exclude',
  theme: 'exclude',
  className: 'exclude',
  iconIsAfterText: 'exclude',
  ariaCurrent: 'exclude'
};

Link.defaultProps = {
  iconName: 'arrow',
  shouldOpenInNewTab: false,
  iconIsAfterText: true
};

Link.colors = colors;
Link.fontThemes = fontThemes;
Link.themes = themes;
Link.animation = animation;

export default Link;
