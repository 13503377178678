import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RichText from 'components/rich-text';
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import Icon from 'components/icon';

// TODO: minside trenger vi denne komponeneten?
const InfoNote = ({ className, popupText, closeButtonText = ' ' }) => {
  const [textIsShown, setTextIsShown] = React.useState(false);
  const parent = React.useRef();
  useEscape(() => {
    setTextIsShown(false);
  });

  useClickOutside(parent, () => {
    setTextIsShown(false);
  });

  const hideText = () => {
    setTextIsShown(false);
  };
  const showText = () => {
    setTextIsShown(true);
  };

  return (
    <div
      ref={parent}
      className={cn('info-note', className, {
        'info-note--text-is-shown': textIsShown
      })}
    >
      <button
        className="info-note__button info-note__button--show"
        onClick={e => {
          e.preventDefault();
          showText();
        }}
        type="button"
      >
        i
      </button>
      <button
        className="info-note__button info-note__button--hide"
        onClick={e => {
          e.preventDefault();
          hideText();
        }}
        aria-label={closeButtonText}
        type="button"
        tabIndex="-1"
      >
        <Icon name="close-modal" className="info-note__hide-icon" />
      </button>
      <div
        className={cn('info-note__text', {
          'info-note__text--is-shown': textIsShown
        })}
      >
        <RichText {...popupText} />
      </div>
    </div>
  );
};

InfoNote.propTypes = {
  className: PropTypes.string,
  popupText: PropTypes.exact(RichText.propTypes),
  closeButtonText: PropTypes.string.isRequired
};

export default InfoNote;
