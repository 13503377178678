import React from 'react';
import PropTypes from 'prop-types';

import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import ArticleHeader from 'components/article-header';
import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import Form from 'components/form';
import Layout from 'components/layout';
import RichText from 'components/rich-text';

const FormPage = ({ form, header, layout, sidebar, text }) => {
  return (
    <Layout {...layout}>
      <ArticleHeader {...header}></ArticleHeader>

      <Columns>
        <Columns.Main>
          <RichText {...text}></RichText>
          {form && <Form {...form} />}
        </Columns.Main>

        <Columns.Sidebar>
          {sidebar && <ComponentList {...sidebar} {...sidebarDefaultOptions} />}
        </Columns.Sidebar>
      </Columns>
    </Layout>
  );
};

FormPage.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes),
  text: PropTypes.exact(RichText.propTypes)
};

export default FormPage;
