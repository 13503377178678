import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Plx from 'react-plx';

import Link from 'components/link';
import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import HousingProjectInformationList from 'components/housing-project-information-list';

const HousingProjectListBlock = ({
  title,
  link,
  description,
  items,
  theme,
  id
}) => {
  const plxData = [
    {
      start: `.housing-project-list-block--${id}`,
      duration: '85vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 300,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  const plxData2 = [
    {
      start: `.housing-project-list-block--${id}`,
      duration: '85vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 200,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  const plxData3 = [
    {
      start: `.housing-project-list-block--${id}`,
      duration: '85vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];
  return (
    <div
      className={cn('housing-project-list-block', {
        [`housing-project-list-block--${theme}`]: theme,
        [`housing-project-list-block housing-project-list-block--${id}`]: id
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader
          title={title}
          link={link ? link : null}
          description={description ? description : null}
          theme={theme}
        />
        {items && items !== 0 && (
          <ul
            className={cn('housing-project-list-block__items', {
              [`housing-prosject-list-block__items housing-project-list-block__items--${items.length}`]: items
            })}
          >
            {items.map((item, index) => (
              <li
                className={cn('housing-project-list-block__item', {
                  [`housing-prosject-list-block__item housing-project-list-block__item--${items.length}`]: items
                })}
                key={item.title}
              >
                <Plx
                  className="housing-project-list-block__plx-wrapper"
                  parallaxData={
                    index % 2 === 0 && index % 3 !== 0
                      ? plxData
                      : index % 3 === 0
                      ? plxData3
                      : plxData2
                  }
                >
                  {item.image && <Image {...item.image} />}

                  {item.title && (
                    <div className="housing-project-list-block__item-title">
                      <Heading level={3} theme={Heading.themes.h3}>
                        {item.title}
                      </Heading>
                    </div>
                  )}
                  {item.text ? (
                    <div className="housing-project-list-block__item-text">
                      {item.text}
                    </div>
                  ) : item.table ? (
                    <HousingProjectInformationList
                      baseTheme={
                        theme === 'green' || theme === 'dark' ? 'dark' : 'light'
                      }
                      {...item.table}
                    />
                  ) : null}
                  {item.link && (
                    <Link
                      className="housing-project-list-block__item-link"
                      animation="shrink"
                      {...item.link}
                    />
                  )}
                </Plx>
              </li>
            ))}
          </ul>
        )}
      </ContentWrapper>
    </div>
  );
};

HousingProjectListBlock.propTypes = {
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  description: PropTypes.string,
  theme: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      table: PropTypes.exact(HousingProjectInformationList.propTypes),
      link: PropTypes.exact(Link.propTypes),
      image: PropTypes.exact(Image.propTypes)
    })
  )
};

HousingProjectListBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectListBlock;
