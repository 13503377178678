import React from 'react';
import PropTypes from 'prop-types';
import EstimatedReadingTime from 'components/estimated-reading-time';

const InspoArticleHeader = ({
  ingress,
  date,
  byline,
  estimatedReadingTime
}) => {
  return (
    <div className="inspo-article-header">
      <div className="inspo-article-header__content">
        {ingress && (
          <div>
            <div className="inspo-article-header__dropcap">
              {ingress.charAt(0)}
            </div>
            <div className="inspo-article-header__ingress">
              {ingress.substr(1)}
            </div>
          </div>
        )}
        {estimatedReadingTime && (
          <EstimatedReadingTime
            {...estimatedReadingTime}
            className="inspo-article-header__estimated-reading-time"
          />
        )}
        {date && <div className="inspo-article-header__date">{date}</div>}

        {byline && (
          <div className="inspo-article-header__byline-container">
            {byline.map(item => (
              <div key={item.text} className="inspo-article-header__byline">
                {item.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

InspoArticleHeader.propTypes = {
  ingress: PropTypes.string,
  date: PropTypes.string,
  byline: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
  estimatedReadingTime: PropTypes.exact(EstimatedReadingTime.propTypes)
};

export default InspoArticleHeader;
