import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Radio from 'components/radio';

const InspoArticleFilters = ({ options, filterId }) => {
  const { setFilter } = React.useContext(FilterContext);

  // NOTE: If the filter has an initial value on mount, the context needs to be updated
  const currentDefaultChecked = options.find(option => option.defaultChecked);

  React.useEffect(() => {
    currentDefaultChecked &&
      currentDefaultChecked.value &&
      setFilter(filterId, currentDefaultChecked.value);
  }, []);

  return (
    <div role="radiogroup" className="inspo-article-filters">
      {options.map(option => (
        <div className="inspo-filters__item" key={option.value}>
          <Radio
            onChange={value => setFilter(filterId, value)}
            theme={Radio.themes.button}
            {...option}
          />
        </div>
      ))}
    </div>
  );
};

InspoArticleFilters.propTypes = {
  filterId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.exact(Radio.propTypes))
};

InspoArticleFilters.defaultProps = {
  options: []
};

export default InspoArticleFilters;
