import React from 'react';
import PropTypes from 'prop-types';

import updateQueryString from 'js/url-helpers/update-query-string';

const Context = React.createContext({
  setFilter: () => {},
  shouldShow: () => true
});

export const FilterContextProvider = ({ children }) => {
  // NOTE: Object with filter category id as keys and the selected filter value as values
  const [activeFiltersMap, setActiveFiltersMap] = React.useState({});

  const setFilter = React.useCallback(
    (filterId, filterValue) =>
      setActiveFiltersMap(currentActiveFilters => ({
        ...currentActiveFilters,
        [filterId]: filterValue
      })),
    []
  );

  React.useEffect(() => {
    updateQueryString(activeFiltersMap);
  }, [activeFiltersMap]);

  // NOTE: empty strings and other falsy values are filtered out
  const activeFilters = React.useMemo(
    () => Object.values(activeFiltersMap).filter(id => id),
    [activeFiltersMap]
  );

  const shouldShow = React.useCallback(
    (filterIds = []) => {
      if (activeFilters.length === 0) {
        return true; // NOTE: Always show all residences when no filters are set
      } else if (filterIds.length === 0) {
        return false; // NOTE: Always hide a residence if filters are set and it has no filter ids
      }
      // NOTE: Show any residences that match all the active filters
      return activeFilters.every(filterId => filterIds.includes(filterId));
    },
    [activeFilters]
  );

  return (
    <Context.Provider value={{ setFilter, shouldShow }}>
      {children}
    </Context.Provider>
  );
};

FilterContextProvider.propTypes = {
  children: PropTypes.node
};

FilterContextProvider.propTypesMeta = 'exclude';

export default Context;
