/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import formatCount from '../../js/format-count';

import FilterContext from 'contexts/filter-context';

import SwiperButton from 'components/swiper-button';
import PromotedPerk from './promoted-perk';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';

const swiperParams = {
  initialSlide: 0,
  spaceBetween: 24,
  height: 250,
  slidesPerView: 'auto',
  centeredSlides: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};

const PromotedPerkList = ({ items, title }) => {
  const { shouldShow } = React.useContext(FilterContext);

  const [currentIndex, setCurrentIndex] = useState(0);

  const listHasItems = () => {
    return items.some(item => shouldShow(item.filterIds));
  };

  const handleNext = () => {
    setCurrentIndex(prev => {
      if (prev + 1 < items.length) {
        return prev + 1;
      }
      return prev;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex(prev => {
      if (prev - 1 >= 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const countText = React.useMemo(
    () => formatCount(currentIndex, items.length),
    [currentIndex, items.length]
  );

  return listHasItems() ? (
    <ContentWrapper className="perk-list-page__perk-list perk-list-page__promoted-perk-list">
      <Heading
        level={2}
        theme={Heading.themes.h2}
        className="perk-list-page__heading"
      >
        {title}
      </Heading>
      <ul className="promoted-perk-list">
        {items.map(pageListItem => (
          <PromotedPerk
            key={`${pageListItem.url}${pageListItem.title}$`}
            isSlide={false}
            {...pageListItem}
          />
        ))}
      </ul>
      <div className="promoted-perk-slides">
        <Swiper
          modules={[Navigation]}
          containerClass="promoted-perk-slides"
          {...swiperParams}
          onTransitionEnd={swiper => {
            if (swiper.isEnd) {
              setCurrentIndex(items.length - 1);
            } else if (swiper.isBeginning) {
              setCurrentIndex(0);
            } else {
              swiper && setCurrentIndex(swiper.activeIndex);
            }
          }}
        >
          {items.map(pageListItem => (
            <SwiperSlide key={`${pageListItem.url}${pageListItem.title}$`}>
              <PromotedPerk isSlide={true} {...pageListItem} />
            </SwiperSlide>
          ))}
          <div className="promoted-perk__navigation-wrapper">
            <div className="swiper-button-prev promoted-perk__navigation-button">
              <SwiperButton
                theme={SwiperButton.themes.previous}
                text="Forrige"
                isActive={true}
                onClick={handlePrevious}
              />
            </div>
            <div className="promoted-perk__navigation-text">{countText}</div>
            <div className="swiper-button-next promoted-perk__navigation-button">
              <SwiperButton
                theme={SwiperButton.themes.next}
                text="Neste"
                isActive={true}
                onClick={handleNext}
              />
            </div>
          </div>
        </Swiper>
      </div>
    </ContentWrapper>
  ) : null;
};

PromotedPerkList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(PromotedPerk.propTypes)),
  missingItemsText: PropTypes.string
};

export default PromotedPerkList;
