import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from 'components/content-wrapper';
import Image from 'components/image';
import Link from 'components/link';
import Text from 'components/text';

const ImageListBlock = ({ items }) => {
  return (
    <div className="image-list-block">
      {items.map(({ text, url, image }) => (
        <div className="image-list-block__item" key={text + url + image.src}>
          <div className="image-list-block__image">
            <Image theme={Image.themes.background} {...image} />
          </div>
          {text && (
            <ContentWrapper
              className="image-list-block__text"
              theme={ContentWrapper.themes.smallScreenOnly}
            >
              <Text theme={Text.themes.ingressSmall}>
                {url ? <Link url={url} text={text} /> : text}
              </Text>
            </ContentWrapper>
          )}
        </div>
      ))}
    </div>
  );
};

ImageListBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.exact(Image.propTypes).isRequired
    })
  )
};

ImageListBlock.defaultProps = {
  items: []
};

export default ImageListBlock;
