import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Text from 'components/text';

const QuoteBlock = ({ description, link, quote, quotee }) => {
  return (
    <div className="quote-block">
      <blockquote>
        <p>{quote}</p>
        {quotee && <footer>{quotee}</footer>}
      </blockquote>

      {description && (
        <Text
          className="quote-block__description"
          theme={Text.themes.ingressSmall}
        >
          {description}
        </Text>
      )}
      {link && (
        <div className="quote-block__link">
          <Link
            animation="expand"
            fontTheme={Link.fontThemes.uppercaseCondensed}
            color={Link.colors.black}
            showIcon={true}
            {...link}
          />
        </div>
      )}
    </div>
  );
};

QuoteBlock.propTypes = {
  description: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  quote: PropTypes.string,
  quotee: PropTypes.string
};

export default QuoteBlock;
