import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useGaTracking from 'hooks/use-ga-tracking';
import styles from './error-page.css';
import illustration from '../../assets/images/404_illustrasjon.png';
import Logo from './logo';

import Header from 'components/header';
import GlobalWarningBlock from 'components/global-warning-block';

// NOTE: Styles are inlined in order to be able to generate a static html page with inline styles (for 5xx errors).
const ErrorPage = ({
  contactText,
  header,
  linkText,
  link,
  title,
  text,
  phone,
  mail
}) => {
  const [warningBlockIsOpen, setWarningBlockIsOpen] = useState(false);

  return (
    <React.Fragment>
      {header?.globalWarningBlock && (
        <GlobalWarningBlock
          {...header?.globalWarningBlock}
          isOpen={warningBlockIsOpen}
          handleIsOpen={setWarningBlockIsOpen}
          isPlaceholder={true}
        />
      )}
      {header && (
        <Header
          {...header}
          handleChangeWarningBlock={setWarningBlockIsOpen}
          globalWarningBlockIsOpen={warningBlockIsOpen}
        />
      )}
      <style>{styles}</style>

      <div className="error-page">
        {header && (
          <img className="error-page__illustration" alt="" src={illustration} />
        )}
        <div className={cn('error-page__content')}>
          {!header && (
            <div className="error-page__logo">
              <Logo />
            </div>
          )}
          <h1>{title}</h1>
          <p className="error-page__description">{text}</p>
          <div className="error-page__links">
            <div className="error-page__link-container">
              {linkText && <h2 className="error-page__subtitle">{linkText}</h2>}
              {link && (
                <a
                  className="error-page__usbl-link-text"
                  href={link.url}
                  onClick={e => {
                    e.preventDefault();
                    useGaTracking('Error', 'Gå til forsiden', '404', link.url);
                  }}
                >
                  {link.text}
                </a>
              )}
            </div>
          </div>
          <div className="error-page__contact">
            {contactText}
            <div className="error-page__contacts">
              {phone && (
                <a className="error-page__contact-item" href={phone.url}>
                  {phone.text}
                </a>
              )}
              {mail && (
                <a className="error-page__contact-item" href={mail.url}>
                  {mail.text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ErrorPage.propTypes = {
  contactText: PropTypes.string,
  header: PropTypes.exact(Header.propTypes),
  linkText: PropTypes.string,
  link: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string
  }),
  title: PropTypes.string,
  text: PropTypes.string,
  phone: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string
  }),
  mail: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string
  })
};

export default ErrorPage;
