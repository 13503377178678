import React from 'react';
import PropTypes from 'prop-types';
import ContactCard from 'components/contact-card';
import Heading from 'components/heading';

const ContactList = ({ title, contacts }) => {
  return (
    <div className="contact-list">
      <Heading theme={Heading.themes.h2}>{title}</Heading>
      <div className="contact-list__contacts">
        {contacts.map(contact => (
          <div
            className="contact-list__contact"
            key={contact.name + contact.jobTitle}
          >
            <ContactCard {...contact} />
          </div>
        ))}
      </div>
    </div>
  );
};

ContactList.propTypes = {
  title: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.exact(ContactCard.propTypes))
};

ContactList.defaultProps = {
  contacts: []
};

export default ContactList;
