import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import RichText from 'components/rich-text';

const themes = {
  beige: 'beige',
  vertical: 'vertical'
};

const alternateTextThemes = [
  RichText.themes.biggerText,
  RichText.themes.redLinks
];

const ContactInfo = ({ groups, theme }) => {
  return (
    <div className={cn('contact-info', { [`contact-info--${theme}`]: theme })}>
      {groups.map(({ title, text }) => (
        <div className="contact-info__group" key={title}>
          <div className="contact-info__title">
            <Heading theme={Heading.themes.h5}>{title}</Heading>
          </div>
          <RichText
            themes={[RichText.themes.compact].concat(
              theme === themes.beige ? [] : alternateTextThemes
            )}
            {...text}
          />
        </div>
      ))}
    </div>
  );
};

ContactInfo.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string.isRequired,
      text: PropTypes.exact(RichText.propTypes).isRequired
    })
  ),
  theme: PropTypes.oneOf(Object.values(themes))
};

ContactInfo.propTypesMeta = {
  theme: 'exclude'
};

ContactInfo.defaultProps = {
  groups: []
};

ContactInfo.themes = themes;

export default ContactInfo;
