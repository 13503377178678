import fromQueryString from '@creuna/utils/from-query-string';
import toQueryString from '@creuna/utils/to-query-string';
import stripPropertiesWithValue from '@creuna/utils/strip-properties-with-value';

import replaceUrl from './replace-url';

const updateQueryString = values => {
  if (process.env.NODE_ENV === 'test') {
    // NOTE: This URL manipulation will make tests fail, so it is ignored while running tests. This block is (probably) removed automatically when building.
    return;
  }

  const queryList = fromQueryString(location.search);

  const newQueryObject = stripPropertiesWithValue(
    { ...queryList, ...values },
    null
  );

  replaceUrl(toQueryString(newQueryObject) || '?');
};

export default updateQueryString;
