import React from 'react';
import PropTypes from 'prop-types';

import { FilterContextProvider } from 'contexts/filter-context';
import mainContentDefaultOptions from 'js/block-area/main-content-default-options';

import ArticleHeader from 'components/article-header';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import EventFilters from 'components/event-filters';
import EventList from 'components/event-list';
import RichText from 'components/rich-text';
import Layout from 'components/layout';

const EventListPage = ({ blocks, events, filters, header, layout }) => {
  return (
    <Layout {...layout}>
      <FilterContextProvider>
        <ArticleHeader {...header}></ArticleHeader>
        <ContentWrapper className="event-list-page__content">
          {filters && <EventFilters {...filters} />}
          {events && (
            <div className="event-list-page__events">
              <EventList {...events} />
            </div>
          )}

          {blocks && (
            <div className="event-list-page__blocks">
              <ComponentList {...blocks} {...mainContentDefaultOptions} />
            </div>
          )}
        </ContentWrapper>
      </FilterContextProvider>
    </Layout>
  );
};

EventListPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  events: PropTypes.exact(EventList.propTypes),
  filters: PropTypes.exact(EventFilters.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  text: PropTypes.exact(RichText.propTypes)
};

export default EventListPage;
