import React from 'react';
import PropTypes from 'prop-types';

const FancyDate = ({ dayFrom, dayTo, monthFrom, monthTo }) => {
  const shouldShowMonthFrom = monthFrom && monthFrom !== monthTo;

  return (
    <div className="fancy-date">
      <span className="fancy-date__day">{dayFrom}</span>
      {shouldShowMonthFrom && (
        <span className="fancy-date__month">{monthFrom}</span>
      )}
      {monthTo && dayTo && <span className="fancy-date__separator">-</span>}
      <span className="fancy-date__day">{dayTo}</span>
      <span className="fancy-date__month">{monthTo}</span>
    </div>
  );
};

FancyDate.propTypes = {
  dayFrom: PropTypes.string,
  dayTo: PropTypes.string,
  monthFrom: PropTypes.string,
  monthTo: PropTypes.string
};

FancyDate.defaultProps = {
  dayFrom: '',
  dayTo: ''
};

export default FancyDate;
