import React from 'react';
import PropTypes from 'prop-types';

import ContactInfo from 'components/contact-info';
import Drawer from 'components/drawer';
import Form from 'components/form';
import Heading from 'components/heading';

const ContactForm = ({ contactInfo, hideText, form, hide, title }) => {
  return (
    <Drawer hide={hide} hideText={hideText}>
      <div className="contact-form">
        <Heading>{title}</Heading>
        {form && <Form {...form} />}
      </div>
      {contactInfo && (
        <ContactInfo theme={ContactInfo.themes.beige} {...contactInfo} />
      )}
    </Drawer>
  );
};

ContactForm.propTypes = {
  contactInfo: PropTypes.exact(ContactInfo.propTypes),
  form: PropTypes.exact(Form.propTypes),
  hide: PropTypes.func,
  hideText: PropTypes.string,
  title: PropTypes.string
};

export default ContactForm;
