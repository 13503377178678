import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Video = ({
  url,
  type,
  containerClassName,
  shouldLoop,
  videoClassName
}) => {
  return (
    <div className={cn('video__container', containerClassName)}>
      <video
        className={cn('video', videoClassName)}
        autoPlay
        autobuffer="true"
        muted
        playsInline
        loop={shouldLoop ? true : false}
      >
        <source src={url} type={type}></source>
      </video>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  containerClassName: PropTypes.string,
  shouldLoop: PropTypes.bool,
  videoClassName: PropTypes.string
};

Video.propTypesMeta = {
  containerClassName: 'exclude',
  videoClassName: 'exclude',
  shouldLoop: 'exclude'
};

export default Video;
