import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ComponentListItem from 'components/component-list-item';

/*
  NOTE: additionalComponentProps can be used to send props to all components of a specific type.
  This only applies to the components rendered directly by ComponentListItem (in other words these props don't trickle all the way down the render tree)

  Example: forcing a 'blue' theme for all Links rendered by a ComponentList:
  <ComponentList additionalComponentProps={{ Link: { theme: Link.themes.blue } }} />

  NOTE: wrapperElementProps can be used to style the wrapper element around blocks depending on what component is rendered.

  Example: rendering all Links as wide:
  <ComponentList wrapperElementProps={{ Link: { isWide: true } }} />
*/

const themes = {
  alignElementsBottom: 'align-elements-bottom'
};

const ComponentList = ({
  additionalComponentProps, // See above comment
  components,
  componentNotSupportedText,
  theme,
  wrapperElementProps,
  isInspoArticle
}) =>
  components.length === 0 ? null : (
    <div
      className={cn('component-list', { [`component-list--${theme}`]: theme })}
    >
      {components.map(component => (
        <ComponentListItem
          additionalComponentProps={additionalComponentProps}
          componentNotSupportedText={componentNotSupportedText}
          key={component.id}
          wrapperElementProps={wrapperElementProps}
          isInspoArticle={isInspoArticle}
          {...component}
        />
      ))}
    </div>
  );

ComponentList.propTypes = {
  additionalComponentProps: PropTypes.object,
  components: PropTypes.arrayOf(PropTypes.exact(ComponentListItem.propTypes)),
  componentNotSupportedText: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  wrapperElementProps: PropTypes.any,
  isInspoArticle: PropTypes.bool
};

ComponentList.propTypesMeta = {
  additionalComponentProps: 'exclude',
  wrapperElementProps: 'exclude',
  isInspoArticle: 'exclude'
};

ComponentList.defaultProps = {
  additionalComponentProps: {},
  components: []
};

ComponentList.themes = themes;

export default ComponentList;
