import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  black: 'black',
  white: 'white',
  slate: 'slate',
  greenDark: 'green-dark'
};

const themes = {
  ingress: 'ingress',
  ingressSmall: 'ingress-small',
  uppercaseCondensed: 'uppercase-condensed'
};

const Text = ({ children, className, theme, color }) => {
  return !children
    ? null
    : React.createElement(
        'p',
        {
          className: cn(
            'text',
            {
              [`text--theme-${theme}`]: theme,
              [`text--color-${color}`]: color
            },
            className
          )
        },
        children
      );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  color: PropTypes.oneOf(Object.values(colors))
};

Text.propTypesMeta = {
  theme: 'exclude',
  color: 'exclude',
  className: 'exclude'
};

Text.themes = themes;
Text.colors = colors;

export default Text;
