import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';
import ComponentList from 'components/component-list';

import Columns from 'components/columns';
import ContentWrapper from 'components/content-wrapper';
import FancyDate from 'components/fancy-date';
import Heading from 'components/heading';
import Layout from 'components/layout';
import CallToAction from 'components/call-to-action';
import Metadata from 'components/metadata';
import Text from 'components/text';
import RichText from 'components/rich-text';

const EventPage = ({
  blocks,
  date,
  ingress,
  layout,
  callToAction,
  metadata,
  title,
  text
}) => {
  return (
    <Layout {...layout}>
      <Columns>
        <Columns.Main />
        <Columns.Sidebar className="event-page__date-wrapper">
          {date && (
            <div className="event-page__date">
              <FancyDate {...date} />
            </div>
          )}
        </Columns.Sidebar>
      </Columns>

      <Columns className="event-page__header">
        <Columns.Main>
          <div className="event-page__header-title">
            <Heading level={1} theme={Heading.themes.h1}>
              {title}
            </Heading>
          </div>
          {metadata && (
            <div className="event-page__metadata">
              <Metadata {...metadata} />
            </div>
          )}
          {ingress && (
            <div className="event-page__ingress">
              <Text theme={Text.themes.ingress}>{ingress}</Text>
            </div>
          )}
          {text && (
            <RichText themes={[RichText.themes.eventHeader]} {...text} />
          )}
        </Columns.Main>
      </Columns>

      <ContentWrapper>
        {callToAction && (
          <div className="event-page__link">
            <CallToAction theme="button" {...callToAction}></CallToAction>
          </div>
        )}
      </ContentWrapper>

      {blocks && (
        <ContentWrapper>
          <ComponentList {...blocks} {...mainContentDefaultOptions} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

EventPage.propTypes = {
  callToAction: PropTypes.exact(CallToAction.propTypes),
  blocks: PropTypes.exact(ComponentList.propTypes),
  date: PropTypes.exact(FancyDate.propTypes),
  ingress: PropTypes.string,
  layout: PropTypes.exact(Layout.propTypes),
  metadata: PropTypes.exact(Metadata.propTypes),
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes)
};

export default EventPage;
