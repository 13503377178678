import React from 'react';
import PropTypes from 'prop-types';

import { FilterContextProvider } from 'contexts/filter-context';
import mainContentDefaultOptions from 'js/block-area/main-content-default-options';

import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import Filters from 'components/filters';
import Heading from 'components/heading';
import Layout from 'components/layout';
import PageList from 'components/page-list';
import Pagination from 'components/pagination';
import PromoBlock from 'components/promo-block';
import Text from 'components/text';
import PromotedPerkList from 'components/promoted-perk-list';

const PerkListPage = ({
  filters,
  ingress,
  layout,
  promotedPage,
  title,
  pageList,
  promotedPerkList,
  pagination,
  blocks
}) => {
  return (
    <Layout {...layout}>
      <FilterContextProvider>
        <Columns>
          <Columns.Main>
            <div className="perk-list-page__header">
              <Heading level={1} theme={Heading.themes.h1}>
                {title}
              </Heading>
              <Text theme={Text.themes.ingress}>{ingress}</Text>
            </div>
          </Columns.Main>
        </Columns>

        {promotedPage && (
          <div className="perk-list-page__promoted">
            <PromoBlock {...promotedPage} align={PromoBlock.alignments.left} />
          </div>
        )}
        {filters && (
          <ContentWrapper className="perk-list-page__filters">
            <Filters {...filters} />
          </ContentWrapper>
        )}
        {promotedPerkList && <PromotedPerkList {...promotedPerkList} />}
        <ContentWrapper className="perk-list-page__perk-list">
          <PageList {...pageList} />
          {pagination && <Pagination {...pagination} />}
        </ContentWrapper>
      </FilterContextProvider>
      {blocks && (
        <ContentWrapper>
          <ComponentList {...blocks} {...mainContentDefaultOptions} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

PerkListPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  filters: PropTypes.exact(Filters.propTypes),
  ingress: PropTypes.string,
  layout: PropTypes.exact(Layout.propTypes),
  pageList: PropTypes.exact(PageList.propTypes),
  promotedPerkList: PropTypes.exact(PromotedPerkList.propTypes),
  promotedPage: PropTypes.exact(PromoBlock.propTypes),
  title: PropTypes.string.isRequired,
  pagination: PropTypes.exact(Pagination.propTypes)
};

export default PerkListPage;
