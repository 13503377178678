import React from 'react';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="41"
    width="81"
    viewBox="0 0 81.3 41.2"
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:#fff}'}</style>
    <path
      fill="white"
      d="M16 33.6c-1.6 1.2-3.1 2.1-4.9 2.1-1.5 0-4.3-.5-4.3-5.3V17H.7v15.7c0 4.8 4.2 8 7.6 8 2.1 0 5 .1 8-2.4l.2 1.8h5.8V17H16v16.6zM38.5 26.3c-2.8-.7-6.1-.8-6.1-3 0-1.5 1.4-2.2 3.5-2.2 1.7 0 4.1 1 5.4 2.6l4.4-2.9c-1.9-2.8-5.3-4.4-9.8-4.4-5.2 0-9.4 2.2-9.4 6.9 0 4.1 3.1 6.7 8 7.3 2.7.3 5.5 1.6 5.5 3.1 0 1.4-1.6 2.2-4 2.2s-5-1.4-6-3.6l-4.6 2.9c2 2.7 4.1 5.4 10.4 5.4 6.3 0 10-2.5 10-7.5 0-4.2-3.8-5.8-7.3-6.8z"
    />
    <path
      fill="white"
      d="M16 33.6c-1.6 1.2-3.1 2.1-4.9 2.1-1.5 0-4.3-.5-4.3-5.3V17H.7v15.7c0 4.8 4.2 8 7.6 8 2.1 0 5 .1 8-2.4l.2 1.8h5.8V17H16v16.6zM38.5 26.3c-2.8-.7-6.1-.8-6.1-3 0-1.5 1.4-2.2 3.5-2.2 1.7 0 4.1 1 5.4 2.6l4.4-2.9c-1.9-2.8-5.3-4.4-9.8-4.4-5.2 0-9.4 2.2-9.4 6.9 0 4.1 3.1 6.7 8 7.3 2.7.3 5.5 1.6 5.5 3.1 0 1.4-1.6 2.2-4 2.2s-5-1.4-6-3.6l-4.6 2.9c2 2.7 4.1 5.4 10.4 5.4 6.3 0 10-2.5 10-7.5 0-4.2-3.8-5.8-7.3-6.8zM74.5 8.3h6.1v31.8h-6.1zM31.5 9.4L22.4.3l-2 2-7 7 2 2c.6.5 1.5.5 2.1 0l2.2-2.2c1.5-1.5 4-1.5 5.5 0l3.3 3.3 3-3zM62.8 16.9c-2.7-.5-4.8-.6-7.2.2V8.3h-6.3v30.5s4.3 1.8 9.1 1.8c7.2 0 12.7-3.9 12.7-12.2 0-7.3-5.1-10.9-8.3-11.5zm-.9 17.9c-1.8 1.1-4.2 1.2-6.3.4V22.1c1.6-.7 3.6-.8 5.4-.2 3 1 4 4 4 6.3 0 2.4-.4 4.9-3.1 6.6z"
    />
    <path
      fill="white"
      d="M74.5 8.3h6.1v31.8h-6.1zM31.5 9.4L22.4.3l-2 2-7 7 2 2c.6.5 1.5.5 2.1 0l2.2-2.2c1.5-1.5 4-1.5 5.5 0l3.3 3.3 3-3z"
    />
  </svg>
);

Logo.propTypesMeta = 'exclude';

export default Logo;
