import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Image from 'components/image';
import Heading from 'components/heading';
import useGaTracking from 'hooks/use-ga-tracking';
import Link from 'components/link';
import Icon from 'components/icon';

const NewsModal = ({ closeModalText, title, intro, image, link }) => {
  const [showModal, setShowModal] = useState(true);

  const closeAndTrack = trackingActionText => {
    useGaTracking('NewsPopupOverlay', trackingActionText);
    setShowModal(false);
  };

  const closeModal = () => closeAndTrack('Clicked X');
  const outsideClickClose = () => closeAndTrack('Clicked outside');
  const handleLinkClick = () => {
    closeAndTrack('Clicked link');
    {
      typeof window !== 'undefined' &&
        link &&
        link.url &&
        window.open(link.url, link.shouldOpenInNewTab ? '_blank' : '_self');
    }
  };

  return (
    <div className="news-modal">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        onOutsideClickClose={outsideClickClose}
        className="news-modal__modal"
        overlayClassName="news-modal__modal-overlay"
      >
        <div className="news-modal__modal-content">
          <button className="news-modal__close-button" onClick={closeModal}>
            <span className="news-modal__close-button-text">
              {closeModalText}
            </span>
            <Icon
              className="news-modal__close-button-icon"
              name="close-modal"
            />
          </button>
          {image && (
            <div className="news-modal__image-container">
              <Image {...image} className="news-modal__image" />
            </div>
          )}
          {title && (
            <Heading
              level={2}
              theme={Heading.themes.h3Portal}
              className="news-modal__heading"
            >
              {title}
            </Heading>
          )}
          {intro && <div className="news-modal__intro">{intro}</div>}
          {link && link.text && (
            <div className="news-modal__button-container">
              <button
                className="news-modal__button"
                onClick={e => {
                  e.preventDefault();
                  handleLinkClick();
                }}
              >
                <span className="news-modal__button-text">{link.text}</span>
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

NewsModal.propTypes = {
  closeModalText: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  link: PropTypes.exact(Link.propTypes)
};

export default NewsModal;
