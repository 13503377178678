import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const EstimatedReadingTime = ({
  label,
  lessThanMinuteText,
  timeUnit,
  wordsPerMinuteSpeed,
  className
}) => {
  const [lessThanMinute, setLessThanMinute] = React.useState(false);
  const [readingTime, setReadingTime] = React.useState(' ');

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const textContainer = document.getElementsByClassName(
        'layout__content'
      )[0];
      if (textContainer) {
        document.onreadystatechange = function() {
          var state = document.readyState;

          if (state === 'complete') {
            const text = textContainer.innerText;
            const wordsNumber = text.trim().split(/\s+/).length;
            const time = Math.floor(wordsNumber / wordsPerMinuteSpeed);
            const fewWords = wordsNumber < wordsPerMinuteSpeed;
            setLessThanMinute(fewWords);
            setReadingTime(fewWords ? lessThanMinuteText : time);
          }
        };
      } else {
        setReadingTime(null);
      }
    }
  }, []);

  return label && readingTime ? (
    <div className={cn('estimated-reading-time', className)}>
      {label}{' '}
      {lessThanMinute ? (
        lessThanMinuteText
      ) : (
        <>
          <span className="estimated-reading-time__number">{readingTime}</span>{' '}
          {timeUnit}
        </>
      )}
    </div>
  ) : null;
};

EstimatedReadingTime.propTypes = {
  label: PropTypes.string.isRequired,
  timeUnit: PropTypes.string.isRequired,
  lessThanMinuteText: PropTypes.string.isRequired,
  wordsPerMinuteSpeed: PropTypes.number.isRequired,
  className: PropTypes.string
};

EstimatedReadingTime.propTypesMeta = {
  className: 'exclude'
};

export default EstimatedReadingTime;
