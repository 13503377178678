import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';

const AlertBlock = ({ title, link, textBeforeLink, textAfterLink }) => {
  return (
    <div className="alert-block">
      <div className="alert-block__corner"></div>
      <div className="alert-block__content">
        {title && (
          <Heading level={2} className="alert-block__title">
            {title}
          </Heading>
        )}
        {textBeforeLink && (
          <div className="alert-block__text-before-link">{textBeforeLink}</div>
        )}
        {link && (
          <a
            className="alert-block__link"
            href={link.url}
            target={link.shouldOpenInNewTab ? '_blank' : null}
            rel={
              link.shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null
            }
          >
            <span className="alert-block__link-text">{link.text}</span>
          </a>
        )}
        {textAfterLink && (
          <div className="alert-block__text-after-link">{textAfterLink}</div>
        )}
      </div>
      <div className="alert-block__corner--bottom"></div>
    </div>
  );
};

AlertBlock.propTypes = {
  title: PropTypes.string,
  textBeforeLink: PropTypes.string,
  textAfterLink: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    shouldOpenInNewTab: PropTypes.bool
  })
};

export default AlertBlock;
