import PropTypes from 'prop-types';

export default function arrayOfObjectValues(object) {
  return PropTypes.arrayOf(
    (propValue, key, componentName, location, propFullName) => {
      if (!Object.values(object).includes(propValue[key])) {
        return new Error(
          `Invalid prop \`${propFullName}\` (\`${propValue[key]}\`) supplied to \`${componentName}\`. Validation failed.`
        );
      }
    }
  );
}
