import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentWrapper from 'components/content-wrapper';
import FooterGroup from 'components/footer-group';
import Heading from 'components/heading';
import Link from 'components/link';
import Logo from 'components/logo';
import RichText from 'components/rich-text';
import SocialMediaLink from 'components/social-media-link';
import ContactForm from 'components/contact-form';
import TabTrapper from 'components/tab-trapper';

import useToggle from 'hooks/use-toggle';

const Footer = ({
  contacts,
  groups,
  socialMediaTitle,
  socialMediaLinks,
  theme
}) => {
  const [contactVisible, _toggleContact, hideContact, showContact] = useToggle(
    false
  );

  return (
    <footer
      id="id-footer-hotjar"
      className={cn('footer', { [`footer__${theme}`]: theme })}
    >
      <ContentWrapper className="footer__content">
        <div className="footer__logo-container">
          <Logo theme={Logo.themes.white}></Logo>
        </div>

        <div className="footer__contacts-container">
          <div className="footer__contacts">
            {contacts.map(contact => {
              return (
                <div
                  className="footer__contact"
                  key={contact.form ? contact.form.title : contact.title}
                >
                  {contact.title && (
                    <div className="footer__contact-title">
                      <Heading
                        theme={Heading.themes.h5}
                        color={Heading.colors.white}
                      >
                        {contact.title}
                      </Heading>
                    </div>
                  )}

                  <div className="footer__contact-item">
                    {contact.link && (
                      <Link
                        animation="shrink"
                        color="yellow"
                        fontTheme="regular"
                        {...contact.link}
                      ></Link>
                    )}
                    {contact.text && (
                      <div className="footer__contact-text">
                        <RichText
                          {...contact.text}
                          themes={[RichText.themes.footer]}
                        ></RichText>
                      </div>
                    )}

                    {contact.form && (
                      <button
                        className="footer__contact-form-button"
                        onClick={showContact}
                        type="button"
                      >
                        <span className="footer__contact-form-button-span">
                          {contact.contactFormText}
                        </span>
                      </button>
                    )}
                    {contact.form && (
                      <div className="footer__contact-form-container">
                        <TabTrapper isActive={contactVisible}>
                          {contactVisible && (
                            <ContactForm hide={hideContact} {...contact.form} />
                          )}
                        </TabTrapper>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {
            <div className="footer__social-media-container">
              <div className="footer__social-media">
                <Heading theme={Heading.themes.h5} color={Heading.colors.white}>
                  {socialMediaTitle}
                </Heading>
                <div className="footer__social-media-icons">
                  {socialMediaLinks.map(link => (
                    <SocialMediaLink key={link.text + link.url} {...link} />
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="footer__menu">
          {groups.map(group => (
            <FooterGroup key={group.title} {...group} />
          ))}
        </div>
      </ContentWrapper>
    </footer>
  );
};

Footer.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.exact(RichText.propTypes),
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      }),
      form: PropTypes.exact(ContactForm.propTypes),
      contactFormText: PropTypes.string
    })
  ),
  groups: PropTypes.arrayOf(PropTypes.exact(FooterGroup.propTypes)),
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.exact(SocialMediaLink.propTypes)
  ),
  socialMediaTitle: PropTypes.string,
  theme: PropTypes.string
};

Footer.propTypesMeta = {
  theme: 'exclude'
};

Footer.defaultProps = {
  contacts: [],
  groups: [],
  socialMediaLinks: []
};

export default Footer;
