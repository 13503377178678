import ContactInfo from 'components/contact-info';
import LinkList from 'components/link-list';

// NOTE: Default options for 'ComponentList' when rendered in a sidebar or other small layout.
export default {
  additionalComponentProps: {
    ContactInfo: { theme: ContactInfo.themes.vertical },
    LinkList: { themes: [LinkList.themes.big] }
  }
};
