import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from 'components/content-wrapper';
import Heading from 'components/heading';
import Layout from 'components/layout';
import Link from 'components/link';
import Search from 'components/search';
import Text from 'components/text';

const SearchPage = ({
  layout,
  noResultsText,
  results,
  resultsDescription,
  search,
  title
}) => {
  return (
    <Layout {...layout}>
      <ContentWrapper className="search-page">
        <Heading level={1} theme={Heading.themes.h1}>
          {title}
        </Heading>
        {search && (
          <div className="search-page__search">
            <Search {...search} />
          </div>
        )}

        <div className="search-page__results">
          {results.length > 0 ? (
            <React.Fragment>
              {resultsDescription && <p>{resultsDescription}</p>}
              {results.map(({ link, text }) => (
                <div className="search-page__result" key={link.text}>
                  <Heading level={2} theme={Heading.themes.h2}>
                    <Link animation="expand" {...link} />
                  </Heading>
                  {text && <Text>{text}</Text>}
                </div>
              ))}
            </React.Fragment>
          ) : (
            <p>{noResultsText}</p>
          )}
        </div>
      </ContentWrapper>
    </Layout>
  );
};

SearchPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  noResultsText: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      text: PropTypes.string
    })
  ),
  resultsDescription: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  title: PropTypes.string
};

SearchPage.defaultProps = {
  results: []
};

export default SearchPage;
