import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';

const ImageWithCaption = ({ image, caption, className }) => {
  return (
    <figure className={cn('image-with-caption', className)}>
      {image && <Image {...image} className="image-with-caption__image" />}
      {caption && (
        <figcaption className="image-with-caption__image-caption">
          {caption}
        </figcaption>
      )}
    </figure>
  );
};

ImageWithCaption.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  caption: PropTypes.string,
  className: PropTypes.string
};

ImageWithCaption.propTypesMeta = {
  className: 'exclude'
};

export default ImageWithCaption;
