import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components/link';

const LoginLink = ({ link, linkTheme }) => {
  return (
    <div
      data-hj-suppress
      className={cn('login-link', `login-link--${linkTheme}`)}
    >
      <Link
        animation="expand"
        iconName="lock"
        showIcon={true}
        iconIsAfterText={false}
        theme={
          linkTheme === 'yellow'
            ? Link.themes.loginLinkYellow
            : Link.themes.loginLinkBlack
        }
        color={linkTheme === 'yellow' ? Link.colors.yellow : Link.colors.black}
        {...link}
      />
    </div>
  );
};

LoginLink.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  linkTheme: PropTypes.string
};

LoginLink.propTypesMeta = {
  linkTheme: 'exclude'
};

LoginLink.defaultProps = {
  linkTheme: 'yellow'
};

export default LoginLink;
