import ComponentListItem from 'components/component-list-item';
import ExpanderList from 'components/expander-list';
import LinkList from 'components/link-list';
import RichText from 'components/rich-text';
import ColumnImageBlock from 'components/column-image-block';

const wideTheme = { themes: [ComponentListItem.themes.wide] };

// NOTE: Default options for 'ComponentList' when rendered in a sidebar or other small layout.
export default {
  additionalComponentProps: {
    ExpanderList: { theme: ExpanderList.themes.narrow },
    LinkList: { themes: [LinkList.themes.big] },
    RichText: { themes: [RichText.themes.narrow] },
    ColumnImageBlock: { alignment: ColumnImageBlock.alignments.left }
  },
  wrapperElementProps: {
    CarouselBlock: wideTheme,
    ContactBlock: wideTheme,
    ImageAndCarousel: wideTheme,
    ImageListBlock: wideTheme,
    Map: wideTheme,
    PromoBlock: wideTheme,
    RelatedArticlesCarousel: wideTheme
  }
};
