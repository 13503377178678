import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import Heading from 'components/heading';
import cn from 'classnames';

const themes = {
  regular: 'regular',
  green: 'green'
};

const RelatedPagesBlock = ({ items, theme, title }) => {
  return (
    <div
      className={cn('related-pages-block', {
        [`related-pages-block--${theme}`]: theme
      })}
    >
      {title && (
        <Heading
          theme={Heading.themes.h2}
          className="related-pages-block__title"
        >
          {title}
        </Heading>
      )}

      {items.length > 0 && (
        <div className="related-pages-block__items">
          {items.map(item => (
            <div className="related-pages-block__item" key={item.link.text}>
              {item.image && (
                <div className="related-pages-block__item-image-container">
                  <Image
                    theme={Image.themes.background}
                    {...item.image}
                  ></Image>
                </div>
              )}
              <div className="related-pages-block__item-link-container">
                <Link
                  className="link--in-related-pages"
                  animation="shrink"
                  color={
                    theme === themes.green
                      ? Link.colors.yellowOnMobile
                      : Link.colors.red
                  }
                  {...item.link}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

RelatedPagesBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.exact(Link.propTypes),
      image: PropTypes.exact(Image.propTypes)
    })
  ),
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string
};

RelatedPagesBlock.defaultProps = {
  items: []
};

RelatedPagesBlock.propTypesMeta = {
  theme: 'exclude'
};

RelatedPagesBlock.themes = themes;

export default RelatedPagesBlock;
