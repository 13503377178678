import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import CornerWrapper from 'components/corner-wrapper';
import Image from 'components/image';
import Text from 'components/text';
import Icon from 'components/icon';

const alignments = {
  left: 'left',
  right: 'right'
};

const colors = {
  orange: 'orange',
  beige: 'beige'
};

const PromoBlock = ({
  align,
  color,
  date,
  image,
  ingress,
  title,
  callToAction,
  url,
  shouldOpenInNewTab
}) => {
  return (
    <a
      target={shouldOpenInNewTab ? '_blank' : null}
      rel={shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null}
      href={url}
      className={cn('promo-block', {
        [`promo-block--${align}`]: align,
        [`promo-block--${color}`]: color
      })}
    >
      <div className="promo-block__content">
        {date && (
          <Text
            color={Text.colors.slate}
            theme={Text.themes.uppercaseCondensed}
          >
            {date}
          </Text>
        )}

        <Heading
          level={2}
          theme={Heading.themes.h1}
          color={ingress ? Heading.colors.red : Heading.colors.black}
        >
          <span className="promo-block__title">{title}</span>
        </Heading>

        {ingress && <span className="promo-block__ingress">{ingress}</span>}

        {callToAction && (
          <div className="promo-block__cta-container">
            <span className="promo-block__cta">{callToAction}</span>
            <span className="promo-block__icon">
              <Icon name="arrow" />
            </span>
          </div>
        )}
      </div>
      <div className="promo-block__image-wrapper">
        <CornerWrapper
          theme={color === colors.orange ? CornerWrapper.themes.orange : null}
          align={
            align === alignments.right ? CornerWrapper.alignments.right : null
          }
        >
          <div className="promo-block__image">
            <Image theme={Image.themes.background} {...image} />
          </div>
        </CornerWrapper>
      </div>
    </a>
  );
};

PromoBlock.propTypes = {
  align: PropTypes.oneOf(Object.values(alignments)),
  color: PropTypes.oneOf(Object.values(colors)),
  date: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  ingress: PropTypes.string,
  title: PropTypes.string,
  callToAction: PropTypes.string,
  url: PropTypes.string,
  shouldOpenInNewTab: PropTypes.bool
};

PromoBlock.defaultProps = {
  align: alignments.right,
  color: colors.beige
};

PromoBlock.alignments = alignments;

export default PromoBlock;
