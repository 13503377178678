import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';
import Link from 'components/link';
import VideoListItem from 'components/video-list-item';

const VideoListBlock = ({ title, videoListItems, button }) => {
  return (
    <div className="video-list-block">
      <div className="video-list-block__content">
        {title && (
          <Heading
            theme={Heading.themes.h2}
            className="video-list-block__heading"
          >
            {title}
          </Heading>
        )}
        <div className="video-list-block__items">
          {videoListItems &&
            videoListItems.map(videoItem => <VideoListItem {...videoItem} />)}
        </div>
        {button && (
          <div className="video-list-block__link">
            <Link
              animation="shrink"
              fontTheme={Link.fontThemes.uppercaseCondensed}
              color={Link.colors.black}
              showIcon={true}
              {...button}
            />
          </div>
        )}
      </div>
    </div>
  );
};

VideoListBlock.propTypes = {
  title: PropTypes.string,
  videoListItems: PropTypes.arrayOf(PropTypes.exact(VideoListItem.propTypes)),
  button: PropTypes.exact(Link.propTypes)
};

export default VideoListBlock;
