import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const CognitoForm = ({dataKey, dataForm}) => {
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.setAttribute('data-key', dataKey);
    script.setAttribute('data-form', dataForm);
    script.async = true;

    if (formRef.current) {
      formRef.current.appendChild(script);
    }

    return () => {
      if (formRef.current && formRef.current.contains(script)) {
        formRef.current.removeChild(script);
      }
    };
  }, [dataKey, dataForm]);

  return <div ref={formRef} className="cognito-form"></div>;
};

export default CognitoForm;

CognitoForm.propTypes = {
  dataKey: PropTypes.string,
  dataForm: PropTypes.string
};
