import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Heading from 'components/heading';
import Button from 'components/button';
import RichText from 'components/rich-text';

import cn from 'classnames';

const buttonColors = {
  black: 'black',
  red: 'red'
};

const ImageWithTextItem = ({ title, richText, link, image, buttonColor }) => {
  return (
    <div className="image-with-text-item">
      <div className="image-with-text-item__content">
        {image && (
          <div className="image-with-text-item__image">
            <Image theme={Image.themes.background} {...image} />
          </div>
        )}
        <div className="image-with-text-item__text-container">
          {title && (
            <Heading
              level={2}
              theme={Heading.themes.h3PortalBold}
              className="image-with-text-item__title"
            >
              {title}
            </Heading>
          )}
          {richText && (
            <div className="image-with-text-item__text">
              <RichText {...richText} />
            </div>
          )}
        </div>
        {link && (
          <div className="image-with-text-item__link">
            <Button
              className={cn({
                [`image-with-text-item__link--${buttonColor}`]: buttonColor
              })}
              {...link}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ImageWithTextItem.defaultProps = {
  color: buttonColors.black
};

ImageWithTextItem.propTypes = {
  title: PropTypes.string,
  richText: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Button.propTypes),
  image: PropTypes.exact(Image.propTypes),
  buttonColor: PropTypes.oneOf(Object.values(buttonColors))
};

export default ImageWithTextItem;
