import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const Checkbox = ({
  checked,
  name,
  value,
  label,
  text,
  id,
  typeId,
  onChange,
  disabled,
  hasDisabledStyle,
  className,
  isLoading,
  children
}) => {
  const [isChecked, setisChecked] = React.useState(checked);

  const handleCange = () => {
    setisChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <label
      className={cn(
        'checkbox',
        { 'checkbox--disabled': hasDisabledStyle },
        className
      )}
    >
      <input
        className="checkbox__input"
        type="checkbox"
        name={name}
        onChange={e => handleCange(e)}
        defaultChecked={checked}
        defaultValue={value}
        id={id}
        disabled={disabled}
        data-type-id={typeId}
      />
      <div
        className={cn('checkbox__icon-container', {
          'checkbox__icon-container_checked': checked
        })}
      >
        {isLoading && <div className="checkbox__spinner"></div>}
        {checked && !isLoading && (
          <Icon name="check-for-animation" className="checkbox__icon" />
        )}
      </div>
      <div className="checkbox__text-container">
        <div className="checkbox__label">{label}</div>
        {children ? (
          children
        ) : text ? (
          <span className="checkbox__text">{text}</span>
        ) : null}
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  typeId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hasDisabledStyle: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.element
};

Checkbox.defaultProps = {
  isChecked: false
};

Checkbox.propTypesMeta = {
  onChange: 'exclude',
  className: 'exclude',
  hasDisabledStyle: 'exclude',
  isLoading: 'exclude'
};

export default Checkbox;
