import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Expander from 'components/expander';
import RichText from 'components/rich-text';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';

const HousingProjectFaqBlock = ({
  title,
  content,
  hideText,
  showText,
  theme
}) => {
  return (
    <div
      className={cn('housing-project-faq-block', {
        [`housing-project-faq-block--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <Heading
          level={2}
          theme={Heading.themes.h1}
          className="housing-project-faq-block__heading"
        >
          {title}
        </Heading>
        {content &&
          content.map(({ richText, title, text }) => (
            <Expander
              key={title}
              hideText={hideText}
              showText={showText}
              title={title}
            >
              <div className="expander-list__item-text">
                {text && <p>{text}</p>}
                {richText && (
                  <RichText
                    themes={[RichText.themes.housingProjectFAQBlock]}
                    {...richText}
                  />
                )}
              </div>
            </Expander>
          ))}
      </ContentWrapper>
    </div>
  );
};

HousingProjectFaqBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.exact({
      richText: PropTypes.exact(RichText.propTypes),
      title: PropTypes.string,
      text: PropTypes.string
    })
  ),
  hideText: PropTypes.string.isRequired,
  showText: PropTypes.string.isRequired,
  theme: PropTypes.string
};

HousingProjectFaqBlock.defaultProps = {
  content: []
};

HousingProjectFaqBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectFaqBlock;
