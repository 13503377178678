import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectOption from 'components/select-option';

const TagFilter = ({
  options = [],
  name,
  clearLabel,
  multiselect = false,
  onChange = () => {}
}) => {
  const [clearOption, setClearOption] = useState(
    clearLabel ? { label: clearLabel, value: '' } : null
  );
  const [optionsMap, setOptions] = useState(
    new Map(options.map(o => [o.value, o]))
  );

  useEffect(() => {
    if (clearOption) {
      clearOption.isSelected = ![...optionsMap.values()].find(
        o => o.isSelected
      );
      setClearOption(Object.assign({}, clearOption));
    }
  }, [optionsMap]);

  const refreshOptions = () => {
    setOptions(new Map(optionsMap));
  };

  const handleClearOptionChange = () => {
    for (var option of optionsMap.values()) {
      option.isSelected = false;
    }
    refreshOptions();
    onChange({
      options: [],
      selectedValues: [],
      value: null
    });
  };

  const handleOptionChange = e => {
    var targetOption = optionsMap.get(e.value);
    targetOption.isSelected = !targetOption.isSelected;
    if (!multiselect && targetOption.isSelected) {
      for (var option of optionsMap.values()) {
        option.isSelected = option === targetOption;
      }
    }
    refreshOptions();
    const options = [...optionsMap.values()];
    const selectedValues = options.filter(o => o.isSelected).map(o => o.value);
    const value = (options.find(o => o.isSelected) || {}).value || null;

    onChange({
      options: options,
      selectedValues: selectedValues,
      value: value
    });
  };

  return (
    <div className="tag-filter">
      <ul className="tag-filter__list">
        {clearOption && (
          <li className="tag-filter__list-item" key="clear-all">
            <SelectOption
              onChange={handleClearOptionChange}
              {...clearOption}
              inputName={name}
              id={name + `-SelectId-0`}
              label={clearLabel}
            />
          </li>
        )}
        {[...optionsMap.values()].map((option, index) => (
          <li className="tag-filter__list-item" key={option.value}>
            <SelectOption
              onChange={handleOptionChange}
              {...option}
              inputName={name}
              type={multiselect ? 'checkbox' : 'radio'}
              id={name + `-SelectId-` + (index + 1)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

TagFilter.propTypes = {
  name: PropTypes.string.isRequired,
  multiselect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.exact(SelectOption.propTypes)),
  clearLabel: PropTypes.string,
  onChange: PropTypes.func
};
export default TagFilter;
