/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import CarouselTeaserBlock from 'components/carousel-teaser-block';
import cn from 'classnames';
import Slider from 'react-slick';

const themes = {
  red: 'red',
  yellow: 'yellow',
  green: 'green'
};

const TeasersCarouselBlock = ({
  previousButtonText,
  nextButtonText,
  theme,
  items
}) => {
  const slider = React.useRef();

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        aria-label={nextButtonText}
        onClick={onClick}
        className="teasers-carousel-block__button teasers-carousel-block__button--next"
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        aria-label={previousButtonText}
        onClick={onClick}
        className="teasers-carousel-block__button teasers-carousel-block__button--prev"
      ></button>
    );
  }

  var settings = {
    dots: false,
    infinite: items.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          infinite: items.length > 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 650,
        settings: {
          infinite: items.length > 1,
          slidesToShow: 1
        }
      }
    ]
  };

  return items ? (
    <div
      className={cn('teasers-carousel-block', {
        [`teasers-carousel-block--${theme}`]: theme,
        'teasers-carousel-block--three-items': items.length === 3,
        'teasers-carousel-block--two-items': items.length === 2,
        'teasers-carousel-block--one-item': items.length === 1
      })}
    >
      <Slider
        {...settings}
        ref={slider}
        className="teasers-carousel-block__items"
      >
        {items.map((item, index) => (
          <CarouselTeaserBlock
            {...item}
            theme={theme}
            key={'key' + index}
            className="teasers-carousel-block__item"
          />
        ))}
      </Slider>
    </div>
  ) : null;
};

TeasersCarouselBlock.propTypes = {
  previousButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  items: PropTypes.arrayOf(PropTypes.exact(CarouselTeaserBlock.propTypes))
};

TeasersCarouselBlock.defaultProps = {
  theme: themes.red
};

export default TeasersCarouselBlock;
