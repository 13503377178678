import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon';

const MenuHeaders = ({ headers, onClick, activeHeader }) => {
  return (
    <div className="menu-headers">
      {headers &&
        !!headers.length &&
        headers.map(header => (
          <button
            key={header}
            className={cn('menu-headers__button', {
              'menu-headers__button--active': header === activeHeader
            })}
            onClick={() => onClick(header)}
            aria-expanded={header === activeHeader}
          >
            <span className="menu-headers__button-text">
              {header}
              <Icon name="arrow-thin" className="menu-headers__icon" />
            </span>
          </button>
        ))}
    </div>
  );
};

MenuHeaders.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  activeHeader: PropTypes.string
};

MenuHeaders.propTypesMeta = 'exclude';

export default MenuHeaders;
