import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from 'components/icon';

const themes = {
  next: 'next',
  previous: 'previous'
};

const SwiperButton = ({ className, isActive, onClick, text, theme }) => {
  return (
    <button
      className={cn('swiper-button', className, {
        [`swiper-button--${theme}`]: theme
      })}
      aria-label={text}
      aria-disabled={!isActive}
      disabled={!isActive}
      onClick={onClick}
      type="button"
    >
      <Icon className="swiper-button__icon" name="chevron-big-right" />
    </button>
  );
};

SwiperButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

SwiperButton.propTypesMeta = 'exclude';

SwiperButton.themes = themes;

export default SwiperButton;
