import useEvent from './use-event';

/** Executes the provided callback when a click event occurs outside of the provided ref */
const useClickOutside = (ref, callback = () => {}, excluededClass) => {
  useEvent('click', e => {
    if (
      ref.current &&
      e.target !== ref.current &&
      !ref.current.contains(e.target) &&
      !e.target.classList.contains(excluededClass)
    ) {
      callback();
    }
  });
};

export default useClickOutside;
