import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentWrapper from 'components/content-wrapper';
import MenuGroup from 'components/menu-group';
import LoginLink from 'components/login-link';
import Icon from 'components/icon';

const Menu = ({
  loginLink,
  groups,
  activeGroup,
  handleCloseMenu,
  closeButtonText
}) => {
  return (
    <div className="menu">
      <nav className="menu__container">
        <button className="menu__close-button" onClick={handleCloseMenu}>
          <span className="menu__close-button-text">{closeButtonText}</span>
          <Icon className="menu__close-button-icon" name="x" />
        </button>
        <div className="menu__login-links">
          {loginLink && <LoginLink linkTheme="black" {...loginLink} />}
        </div>
        <ContentWrapper className="menu__content">
          <menu className="menu__groups">
            {groups.map(group => (
              <div
                className={cn('menu__group-container', {
                  'menu__group-container--active': group.title === activeGroup
                })}
                key={group.title}
              >
                <MenuGroup {...group} />
              </div>
            ))}
          </menu>
        </ContentWrapper>
      </nav>
    </div>
  );
};

Menu.propTypes = {
  activeGroup: PropTypes.string,
  handleCloseMenu: PropTypes.func,
  loginLink: PropTypes.exact(LoginLink.propTypes),
  groups: PropTypes.arrayOf(PropTypes.exact(MenuGroup.propTypes)),
  closeButtonText: PropTypes.string
};

Menu.defaultProps = {
  links: [],
  groups: [],
  closeButtonText: 'Lukk'
};

Menu.propTypesMeta = {
  activeGroup: 'exclude',
  handleCloseMenu: 'exclude'
};

export default Menu;
