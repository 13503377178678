import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FilterContext from 'contexts/filter-context';

import Heading from 'components/heading';
import Image from 'components/image';
import Text from 'components/text';

const tagColors = {
  red: 'red',
  yellow: 'yellow',
  green: 'green'
};

const PageListItem = ({
  filterIds,
  image,
  published,
  tagColor,
  tagText,
  text,
  title,
  url
}) => {
  const { shouldShow } = React.useContext(FilterContext);

  return !shouldShow(filterIds) ? null : (
    <a href={url} key={title} className="page-list__item-container">
      <div className="page-list__item">
        <div
          className={cn('page-list__item-image-container', {
            'page-list__item-image-container--border': Boolean(image)
          })}
        >
          {tagText && (
            <div
              className={cn('page-list__item-tag', {
                [`page-list__item-tag--${tagColor}`]: tagColors
              })}
            >
              {tagText}
            </div>
          )}
          {image && (
            <div className="page-list__item-image">
              <Image theme={Image.themes.background} {...image}></Image>
            </div>
          )}
        </div>

        {published && (
          <div className="page-list__item-published">{published}</div>
        )}
        <div className="page-list__item-heading">
          <Heading color={Heading.colors.red}>
            <span className="page-list__item-heading-span">{title}</span>
          </Heading>
        </div>
        {text && (
          <Text
            className="page-list__item-text"
            theme={Text.themes.ingressSmall}
          >
            {text}
          </Text>
        )}
      </div>
    </a>
  );
};

PageListItem.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.exact(Image.propTypes),
  published: PropTypes.string,
  tagText: PropTypes.string,
  tagColor: PropTypes.oneOf(Object.values(tagColors)),
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};

PageListItem.defaultProps = {
  filterIds: []
};

export default PageListItem;
