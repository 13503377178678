import React from 'react';
import PropTypes from 'prop-types';

import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';

import Icon from 'components/icon';

const Drawer = ({ children, hide, hideText }) => {
  const ref = React.useRef();

  useEscape(hide);
  useClickOutside(ref, hide);

  return (
    <div ref={ref} className="drawer">
      {children}
      <button aria-label={hideText} className="drawer__close" onClick={hide}>
        <Icon name="x" />
      </button>
    </div>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.func,
  hideText: PropTypes.string
};

export default Drawer;
