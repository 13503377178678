import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Label = ({ error, className, text, htmlFor }) => {
  return (
    <label
      className={cn('label', className, {
        'label--error': error
      })}
      htmlFor={htmlFor ? htmlFor : null}
    >
      <div className="label__text">{error ? '* ' + text : text}</div>
    </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  htmlFor: PropTypes.string,
  error: PropTypes.bool
};

Label.propTypesMeta = 'exclude';

export default Label;
