import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

const SelectOption = ({
  id,
  value,
  label,
  inputName,
  isSelected,
  onChange,
  type = 'radio'
}) => {
  const handleChange = () => {
    onChange({ value: value });
  };

  return (
    <div className="select-option">
      <input
        className="select-option__input"
        type={type}
        name={inputName}
        defaultChecked={isSelected}
        value={value}
        onChange={handleChange}
        id={id}
      />
      <label
        htmlFor={id}
        className={cn('select-option__label', {
          'select-option__label--checked': isSelected
        })}
      >
        {isSelected && (
          <Icon name="checkbox" className="select-option__label-icon" />
        )}
        <span className="select-option__label-text">{label}</span>
      </label>
    </div>
  );
};

SelectOption.propTypes = {
  id: PropTypes.string,
  inputName: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string
};

SelectOption.propTypesMeta = {
  inputName: 'exclude',
  id: 'exclude',
  type: 'exclude'
};

export default SelectOption;
