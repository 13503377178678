/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Image from 'components/image';
import ContentWrapper from 'components/content-wrapper';

const HousingProjectImageGalleryCarousel = ({
  slides,
  initialId,
  nextButtonText,
  previousButtonText
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(initialId);
  const slider = React.useRef();

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        disabled={currentSlide + 1 >= slides.length}
        aria-label={nextButtonText}
        onClick={onClick}
        className="housing-project-image-gallery-carousel__button-next"
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        disabled={currentSlide <= 0}
        aria-label={previousButtonText}
        onClick={onClick}
        className="housing-project-image-gallery-carousel__button-prev"
      ></button>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    swipeToSlide: true,
    afterChange: current => setCurrentSlide(current),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  React.useEffect(() => {
    slider.current.slickGoTo(initialId);
    setCurrentSlide(initialId);
  }, []);

  return (
    <div className="housing-project-image-gallery-carousel">
      <ContentWrapper>
        <Slider ref={slider} {...settings}>
          {slides &&
            slides.length !== 0 &&
            slides.map((slide, index) => (
              <div
                key={index}
                className="housing-project-image-gallery-carousel__content"
              >
                <div className="housing-project-image-gallery-carousel__image-container">
                  <Image
                    className="housing-project-image-gallery-carousel__image"
                    {...slide.image}
                  ></Image>
                </div>
              </div>
            ))}
        </Slider>
        <div>
          {slides.map((slide, index) => (
            <div key={index}>
              {index === currentSlide && (
                <div className="housing-project-image-gallery-carousel__meta">
                  <div className="housing-project-image-gallery-carousel__counter">
                    {currentSlide + 1 < 10 && 0}
                    {currentSlide + 1}
                    <span className="housing-project-image-gallery-carousel__counter-total">
                      /{slides.length < 10 && 0}
                      {slides.length}
                    </span>
                  </div>
                  <div className="housing-project-image-gallery-carousel__description">
                    {slide.description}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectImageGalleryCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.exact(Image.propTypes),
      description: PropTypes.string
    })
  ),
  initialId: PropTypes.number,
  nextButtonText: PropTypes.string,
  previousButtonText: PropTypes.string
};

HousingProjectImageGalleryCarousel.propTypesMeta = {
  initialId: 'exclude'
};

export default HousingProjectImageGalleryCarousel;
