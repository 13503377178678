import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Button from 'components/button';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import cn from 'classnames';
import RichText from 'components/rich-text';

const HousingProjectScrollingImages = ({
  title,
  text,
  link,
  images,
  theme
}) => {
  return (
    <div
      className={cn('housing-project-scrolling-images', {
        [`housing-project-scrolling-images--${theme}`]: theme
      })}
    >
      <div className="housing-project-scrolling-images__text-content">
        <div className="housing-project-scrolling-images__text-container">
          <ContentWrapper>
            {title && (
              <Heading
                className="housing-project-scrolling-images__title"
                level={2}
                theme={Heading.themes.h1}
              >
                {title}
              </Heading>
            )}
            {text && (
              <div className="housing-project-scrolling-images__text">
                <RichText
                  {...text}
                  className="rich-text--in-housing-project-scrolling-images"
                />
              </div>
            )}
            {link && (
              <div className="housing-project-scrolling-images__link">
                <Button
                  housingProjectBaseTheme={
                    theme === 'green' || theme === 'dark' ? 'dark' : 'light'
                  }
                  housingProjectColorTheme={theme === 'green' ? theme : null}
                  housingProjectButtonTheme="primary"
                  {...link}
                />
              </div>
            )}
          </ContentWrapper>
        </div>
      </div>
      <div className="housing-project-scrolling-images__image-list">
        {images &&
          images.length !== 0 &&
          images.map(image => (
            <div
              key={image.src}
              className="housing-project-scrolling-images__image-container"
            >
              <div className="housing-project-scrolling-images__image">
                <Image theme={Image.themes.background} {...image} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

HousingProjectScrollingImages.propTypes = {
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Button.propTypes),
  images: PropTypes.arrayOf(PropTypes.exact(Image.propTypes)),
  theme: PropTypes.string
};

HousingProjectScrollingImages.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectScrollingImages;
