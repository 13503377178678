import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '../../hooks/use-toggle';
import useEvent from '../../hooks/use-event';
import useClickOutside from '../../hooks/use-click-outside';
import useIsMounted from '../../hooks/use-is-mounted';
import Icon from 'components/icon';
import cn from 'classnames';

const themes = {
  greyBg: 'grey-bg'
};

const DropdownFilter = ({
  className,
  label,
  options = [],
  name,
  onChange = () => {},
  error = false,
  theme,
  hasSearch,
  searchPlaceholder
}) => {
  const [isOpen, toggle, close] = useToggle(false);
  const [hasTouch, setHasTouch] = React.useState(false);
  const [currentOptions, setCurrentOptions] = React.useState([...options]);
  useEvent('touchstart', () => setHasTouch(true));

  const searchRef = React.useRef();

  const fakeSelectRef = React.useRef();
  useClickOutside(
    fakeSelectRef,
    () => {
      close();
    },
    'dropdown-filter__search'
  );

  const [selectedOption, setSelectedOption] = React.useState(
    options.find(o => o.isSelected) || {}
  );

  const isMounted = useIsMounted();

  const handleChange = value => {
    setSelectedOption(options.find(x => x.value === value) || {});
    const currentOptions = options.map(option => {
      const currentOption = Object.assign({}, option);
      currentOption.isSelected = currentOption.value === value;
      return currentOption;
    });
    const selected = currentOptions.find(x => x.value === value) || {};
    onChange(selected.value, selected.label);
    hasSearch && setCurrentOptions(options);
    close();
  };

  const handleSearch = e => {
    const input = e.target.value;

    if (input !== '') {
      const newOptions = options.filter(option =>
        option.label.toLowerCase().includes(input.toLowerCase())
      );
      setCurrentOptions(newOptions);
    } else {
      setCurrentOptions(options);
    }
  };

  React.useEffect(() => {
    onChange(selectedOption.value);
  }, []);

  React.useEffect(() => {
    setCurrentOptions([...options]);
  }, [options]);

  return (
    <div
      ref={dropdownRef => {
        if (isOpen && hasSearch && dropdownRef) {
          searchRef.current.focus();
        }
      }}
      className={cn('dropdown-filter', className, {
        'dropdown-filter--active': isOpen,
        'dropdown-filter--has-touch': hasTouch,
        'dropdown-filter--is-mounted': isMounted,
        'dropdown-filter--error': error,
        [`dropdown-filter--${theme}`]: theme,
        'dropdown-filter--has-one-option': options.length === 1,
        'dropdown-filter--has-search': hasSearch
      })}
    >
      {options && (
        <div>
          <select
            className="dropdown-filter__select"
            name={name}
            onChange={e => handleChange(e.target.value)}
            value={selectedOption.value}
            aria-label={label}
          >
            {options.map(option => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.isDisabled ? option.isDisabled : null}
              >
                {option.label}
              </option>
            ))}
          </select>
          <div className="dropdown-filter__fake">
            <div
              aria-hidden="true"
              className="dropdown-filter__element"
              onClick={options.length === 1 ? null : toggle}
              ref={fakeSelectRef}
            >
              <div className="dropdown-filter__element-text">
                {selectedOption.label}
              </div>
              {options.length !== 1 && (
                <div
                  className={cn('dropdown-filter__icon', {
                    'dropdown-filter__icon--active': isOpen
                  })}
                />
              )}
            </div>
            {isOpen && (
              <div>
                {hasSearch && (
                  <div className="dropdown-filter__search-container">
                    <Icon
                      className="dropdown-filter__search-icon"
                      name="search-small"
                    />
                    <input
                      className="dropdown-filter__search"
                      type="text"
                      placeholder={searchPlaceholder}
                      onChange={handleSearch}
                      ref={searchRef}
                    />
                  </div>
                )}
                <ul className="dropdown-filter__dropdown">
                  {currentOptions
                    .filter(option => option !== selectedOption)
                    .map(option => (
                      <li
                        aria-hidden="true"
                        className="dropdown-filter__option"
                        key={option.value}
                        onClick={() => handleChange(option.value)}
                      >
                        <span className="dropdown-filter__option-text">
                          {option.label}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DropdownFilter.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      isSelected: PropTypes.bool,
      isDisabled: PropTypes.bool
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes)),
  hasSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

DropdownFilter.propTypesMeta = {
  className: 'exclude',
  onChange: 'exclude',
  error: 'exclude',
  theme: 'exclude',
  hasSearch: 'exclude'
};

DropdownFilter.themes = themes;

export default DropdownFilter;
