import React from 'react';
import PropTypes from 'prop-types';
import Map from 'components/map';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import ContentWrapper from 'components/content-wrapper';
import cn from 'classnames';

const HousingProjectMapBlock = ({ title, map, description, theme }) => {
  return (
    <div
      className={cn('housing-project-map-block', {
        [`housing-project-map-block--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader title={title} description={description} />
        {map && <Map {...map} />}
      </ContentWrapper>
    </div>
  );
};

HousingProjectMapBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  map: PropTypes.exact(Map.propTypes),
  theme: PropTypes.string
};

HousingProjectMapBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectMapBlock;
