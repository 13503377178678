import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Link from 'components/link';
import RichText from 'components/rich-text';

const InspoPromoBlock = ({ image, text, link }) => {
  return (
    <div className="inspo-promo-block">
      <div className="inspo-promo-block__content">
        {image && (
          <div className="inspo-promo-block__image">
            <Image theme={Image.themes.background} {...image} />
          </div>
        )}
        {text && <RichText {...text} />}
      </div>
      {link && (
        <div className="inspo-promo-block__link-container">
          <a className="inspo-promo-block__link" href={link.url}>
            <span className="inspo-promo-block__link-span">{link.text}</span>
          </a>
        </div>
      )}
    </div>
  );
};

InspoPromoBlock.propTypes = {
  text: PropTypes.exact(RichText.propTypes),
  image: PropTypes.exact(Image.propTypes),
  link: PropTypes.exact(Link.propTypes)
};

export default InspoPromoBlock;
