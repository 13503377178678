import React from 'react';
import PropTypes from 'prop-types';
import ImageWithCaption from 'components/image-with-caption';

import cn from 'classnames';

const alignments = {
  left: 'left',
  right: 'right'
};

const ColumnImageBlock = ({ image, alignment }) => {
  return (
    <div
      className={cn('column-image-block', {
        [`column-image-block--${alignment}`]: alignment
      })}
    >
      <ImageWithCaption {...image} />
    </div>
  );
};

ColumnImageBlock.deafultProps = {
  alignments: alignments.right
};

ColumnImageBlock.propTypes = {
  image: PropTypes.exact(ImageWithCaption.propTypes),
  alignment: PropTypes.oneOf(Object.values(alignments))
};

ColumnImageBlock.propTypesMeta = {
  alignment: 'exclude'
};

ColumnImageBlock.alignments = alignments;

export default ColumnImageBlock;
