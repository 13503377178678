import React from 'react';
import PropTypes from 'prop-types';
import useClickOutside from 'hooks/use-click-outside';
import cn from 'classnames';
import useEvent from 'hooks/use-event';

import Icon from 'components/icon';

const FrontpageHeaderConfirmation = ({
  children,
  closeButtonLabel = 'Lukk'
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const FrontpageHeaderConfirmationRef = React.useRef();

  useClickOutside(
    FrontpageHeaderConfirmationRef,
    () => {
      setOpen(false);
    },
    'cookie-banner__button'
  );

  const onClose = () => {
    setOpen(false);
  };

  const FrontpageHeaderConfirmationPosition = () => {
    if (typeof document === 'undefined') return;
    const FrontpageHeaderConfirmationPanel = document.getElementById(
      'frontpage-confirmation'
    );
    const FrontpageHeaderConfirmationPanelArrow = document.getElementById(
      'frontpage-confirmation-arrow'
    );
    const loginLink = document.getElementsByClassName(
      'login-link__link--with-confirmation'
    )[0];

    if (
      loginLink !== undefined &&
      FrontpageHeaderConfirmationPanel !== undefined &&
      FrontpageHeaderConfirmationPanelArrow !== undefined
    ) {
      const loginLinkLeftPossition = loginLink.offsetLeft;
      const leftPosition = Math.abs(loginLinkLeftPossition) * -1;
      FrontpageHeaderConfirmationPanel.style.left = leftPosition + 'px';
    }
  };

  useEvent('resize', () => FrontpageHeaderConfirmationPosition());

  React.useEffect(() => {
    FrontpageHeaderConfirmationPosition();
    setOpen(true);
  }, []);

  return (
    <div
      ref={FrontpageHeaderConfirmationRef}
      className={cn('frontpage-header-confirmation', {
        'frontpage-header-confirmation--shown': isOpen
      })}
      id="frontpage-confirmation"
    >
      <button
        className="frontpage-header-confirmation__close-button"
        onClick={onClose}
        aria-expanded={isOpen}
        aria-label={closeButtonLabel}
      >
        <Icon
          className="frontpage-header-confirmation__close-button-icon"
          name="close-modal"
        />
      </button>
      <div
        id="frontpage-confirmation-arrow"
        className="frontpage-header-confirmation__arrow"
      />
      {children}
    </div>
  );
};

FrontpageHeaderConfirmation.propTypes = {
  children: PropTypes.node,
  closeButtonLabel: PropTypes.string
};

FrontpageHeaderConfirmation.propTypesMeta = 'exclude';

export default FrontpageHeaderConfirmation;
