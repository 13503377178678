import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Radio from 'components/radio';

const EventFilters = ({ accessibilityTitle, options, filterId }) => {
  const { setFilter } = React.useContext(FilterContext);

  // NOTE: If the filter has an initial value on mount, the context needs to be updated
  const currentDefaultChecked = options.find(option => option.defaultChecked);

  React.useEffect(() => {
    currentDefaultChecked &&
      currentDefaultChecked.value &&
      setFilter(filterId, currentDefaultChecked.value);
  }, []);

  return (
    <div
      aria-label={accessibilityTitle}
      role="radiogroup"
      className="event-filters"
    >
      {options.map(option => (
        <div className="event-filters__item" key={option.value}>
          <Radio
            onChange={value => setFilter(filterId, value)}
            theme={Radio.themes.link}
            {...option}
          />
        </div>
      ))}
    </div>
  );
};

EventFilters.propTypes = {
  accessibilityTitle: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.exact(Radio.propTypes))
};

export default EventFilters;
