import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';

const InspoBulletList = ({title, useOrderedList, list}) => {
  const ListTag = useOrderedList ? 'ol' : 'ul';

  return (
    <div className="inspo-bullet-list">
      <div className="inspo-bullet-list__content">
        <div className="inspo-bullet-list__corner"></div>
        <Heading
          className="inspo-bullet-list__title"
          level={2}
          theme={Heading.themes.h3}
        >
          {title}
        </Heading>
        <ListTag className="inspo-bullet-list__list">
          {list.map(item => (
            <li className="inspo-bullet-list__item" key={item.text}>
              {item.text}
            </li>
          ))}
        </ListTag>
      </div>
    </div>
  );
};

InspoBulletList.propTypes = {
  title: PropTypes.string,
  useOrderedList: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({text: PropTypes.string}))
};

InspoBulletList.defaultProps = {
  list: []
};

export default InspoBulletList;
