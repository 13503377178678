import React from 'react';
import PropTypes from 'prop-types';

const VacantPositionsBlock = ({ id }) => {
  return (
    <div className="vacant-positions-block">
      <p
        id={id}
        dangerouslySetInnerHTML={{
          __html:
            '<script type="text/javascript" src="https://web106.reachmee.com/customerjs/helper.js"></script><script type="text/javascript" src="https://web106.reachmee.com/customerjs/I020-1571-9.js"></script>'
        }}
      ></p>
    </div>
  );
};

VacantPositionsBlock.propTypes = {
  id: PropTypes.string
};

export default VacantPositionsBlock;
