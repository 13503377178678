/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import Image from 'components/image';
import Modal from 'react-modal';
import HousingProjectImageGalleryCarousel from 'components/housing-project-image-gallery-carousel';
import Icon from 'components/icon';

const HousingProjectImageGalleryItem = ({
  title,
  carousel,
  id,
  openCarouselButtonText,
  closeCarouselButtonText
}) => {
  const [carouselIsOpen, setCarouselIsOpen] = React.useState(false);
  const [currentSlideId, setCurrentSlideId] = React.useState();

  const openCarousel = slideId => {
    setCarouselIsOpen(true);
    setCurrentSlideId(slideId);
  };

  const closeCarousel = () => {
    setCarouselIsOpen(false);
  };

  return (
    <div id={id} className="housing-project-image-gallery-item">
      {title && (
        <Heading
          className="housing-project-image-gallery-item__title"
          level={2}
          theme={Heading.themes.h1}
        >
          {title}
        </Heading>
      )}
      {carousel.slides && (
        <div className="housing-project-image-gallery-item__images">
          {carousel.slides.map((slide, index) => (
            <button
              aria-label={openCarouselButtonText}
              onClick={e => {
                e.preventDefault();
                openCarousel(index);
              }}
              key={index}
              className="housing-project-image-gallery-item__image-container"
            >
              <Image
                className="housing-project-image-gallery-item__image"
                {...slide.image}
              />
            </button>
          ))}
        </div>
      )}
      <Modal
        className="housing-project-image-gallery-item__modal"
        overlayClassName="housing-project-image-gallery-item__modal-overlay"
        isOpen={carouselIsOpen}
      >
        <div className="housing-project-image-gallery-item__modal-close-button-container">
          <button
            aria-label={closeCarouselButtonText}
            className="housing-project-image-gallery-item__modal-close-button"
            onClick={closeCarousel}
          >
            <Icon
              className="housing-project-image-gallery-item__modal-close-button-icon"
              name="x"
            ></Icon>
          </button>
        </div>
        {
          <HousingProjectImageGalleryCarousel
            initialId={currentSlideId}
            {...carousel}
          ></HousingProjectImageGalleryCarousel>
        }
      </Modal>
    </div>
  );
};

HousingProjectImageGalleryItem.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  carousel: PropTypes.exact(HousingProjectImageGalleryCarousel.propTypes),
  openCarouselButtonText: PropTypes.string,
  closeCarouselButtonText: PropTypes.string
};

export default HousingProjectImageGalleryItem;
