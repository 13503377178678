import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const HousingProjectInformationList = ({ items, baseTheme }) => {
  return (
    <div
      className={cn('housing-project-information-list', {
        [`housing-project-information-list--${baseTheme}`]: baseTheme
      })}
    >
      {items && (
        <dl className="housing-project-information-list__items">
          {items.map(item => (
            <React.Fragment key={item.label}>
              <dt className="housing-project-information-list__label">
                {item.label}
              </dt>
              <dd className="housing-project-information-list__text">
                {item.text}
              </dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </div>
  );
};

HousingProjectInformationList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, text: PropTypes.string })
  ),
  baseTheme: PropTypes.string
};

HousingProjectInformationList.propTypesMeta = {
  baseTheme: 'exclude'
};

export default HousingProjectInformationList;
