import React from 'react';
import PropTypes from 'prop-types';

import InspoFullWidthMediaBlock from 'components/inspo-full-width-media-block';

const InspoFullWidthMediaBlockList = ({ items }) => {
  return (
    <div className="inspo-full-width-media-block-list">
      {items.map(item => (
        <InspoFullWidthMediaBlock key={item.id} {...item} />
      ))}
    </div>
  );
};

InspoFullWidthMediaBlockList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(InspoFullWidthMediaBlock.propTypes))
};

export default InspoFullWidthMediaBlockList;
