import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Link from 'components/link';
import Heading from 'components/heading';

const supportedIconNames = ['download', 'location', 'floor-plan', 'new-window'];

const IconLinkList = ({ items, id, title }) => {
  return (
    <div className="icon-link-list" key={id}>
      {title && (
        <Heading
          className="icon-link-list__title"
          color={Heading.colors.slate}
          theme={Heading.themes.h5}
        >
          {title}
        </Heading>
      )}
      <ul className="icon-link-list__items">
        {items.map(({ icon, link }) => (
          <li className="icon-link-list__item" key={link.text + link.url}>
            <Icon className="icon-link-list__item-icon" name={icon} />
            <Link animation="expand" {...link} />
          </li>
        ))}
      </ul>
    </div>
  );
};

IconLinkList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.oneOf(supportedIconNames).isRequired,
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  id: PropTypes.string.isRequired,
  title: PropTypes.string
};

IconLinkList.defaultProps = {
  items: []
};

export default IconLinkList;
