import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import useToggle from 'hooks/use-toggle';

import Heading from 'components/heading';
import Link from 'components/link';

const MenuGroup = ({ firstList, secondList, thirdList, title }) => {
  const [isOpen, toggle] = useToggle(false);

  const renderList = list => {
    return (
      list.length > 0 && (
        <ul className="menu-group__items">
          {list.map(item => (
            <li className="menu-group__item" key={item.text}>
              <Link
                className="menu-group__link"
                animation="expand"
                color={Link.colors.black}
                theme="menu"
                showIcon={true}
                iconName="arrow-thin"
                iconIsAfterText={false}
                {...item}
              />
            </li>
          ))}
        </ul>
      )
    );
  };

  return (
    <div className="menu-group">
      <div className="menu-group__heading">
        <Heading>
          <button
            aria-expanded={isOpen}
            className={cn('menu-group__button', {
              'menu-group__button--active': isOpen
            })}
            onClick={e => {
              e.stopPropagation();
              toggle();
            }}
          >
            <div
              className={cn('menu-group__arrow', {
                'menu-group__arrow--active': isOpen
              })}
            />
            {title}
          </button>
        </Heading>
      </div>
      <div className="menu-group__expanded-list">
        {firstList && renderList(firstList)}
        {secondList && renderList(secondList)}
        {thirdList && renderList(thirdList)}
      </div>
      <div className="menu-group__dropdown-list">
        <Collapse isOpen={isOpen}>
          <div>
            {firstList && renderList(firstList)}
            {secondList && renderList(secondList)}
            {thirdList && renderList(thirdList)}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

MenuGroup.propTypes = {
  firstList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  secondList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  thirdList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  title: PropTypes.string.isRequired
};

export default MenuGroup;
