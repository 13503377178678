import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import useGaTracking from 'hooks/use-ga-tracking';

const MembershipBenefitsBonusLink = ({ url, text, shouldOpenInNewTab }) => {
  const urlParts = /^(?:\w+:\/\/)?([^/]+)(.*)$/.exec(url);
  const hostname = urlParts[1]; //to be able to push hostname only to dataLayer for GA

  return (
    <div className="membership-benefits-bonus-link">
      <a
        className="membership-benefits-bonus-link__link"
        href={url}
        target={shouldOpenInNewTab ? '_blank' : null}
        rel={shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null}
        onClick={e => {
          e.preventDefault();
          useGaTracking(
            'Medlemsfordeler',
            'Adtraction link click',
            hostname,
            url,
            shouldOpenInNewTab
          );
        }}
      >
        <span className="membership-benefits-bonus-link__link-text">
          {text}
        </span>
        <Icon
          className="membership-benefits-bonus-link__icon"
          name="external-link-small"
        />
      </a>
    </div>
  );
};

MembershipBenefitsBonusLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  shouldOpenInNewTab: PropTypes.bool
};

export default MembershipBenefitsBonusLink;
