import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ErrorText = ({ text, className }) => {
  return <div className={cn('error-text', className)}>{text}</div>;
};

ErrorText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

ErrorText.propTypesMeta = 'exclude';

export default ErrorText;
