import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';

const isRunningOnClient =
  typeof window !== 'undefined' && window.document ? true : false;
// NOTE: google-maps does stuff with window and is not safe for server side rendering. It's only imported client side (webpack converts commonjs import to something else that works in the browser).
const GoogleMapsLoader = isRunningOnClient ? require('google-maps') : false;

import mapOptions from './map-options';

import ContentWrapper from 'components/content-wrapper';

class Map extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    googleMapsAPIKey: PropTypes.string,
    htmlId: PropTypes.string,
    markers: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.string,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
      })
    )
  };

  static propTypesMeta = {
    markers: [
      {
        latitude: 'float',
        longitude: 'float'
      }
    ]
  };

  static defaultProps = {
    markers: []
  };

  google = null;
  map = null;
  mapElement = null;

  getBounds = markers => {
    const google = this.google;
    const bounds = new google.maps.LatLngBounds();

    if (markers && markers.length) {
      markers.forEach(marker => {
        bounds.extend(
          new google.maps.LatLng(marker.latitude, marker.longitude)
        );
      });
    }

    return bounds;
  };

  fitMapToMarkers = markers => {
    const bounds = this.getBounds(markers);

    if (markers.length === 1) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(16);
    } else {
      this.map.fitBounds(bounds);
    }
  };

  componentDidMount() {
    GoogleMapsLoader.KEY = this.props.googleMapsAPIKey;
    GoogleMapsLoader.VERSION = '3.36';
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
    GoogleMapsLoader.load(google => {
      this.google = google;
      this.map = new google.maps.Map(this.mapElement, mapOptions);

      this.fitMapToMarkers(this.props.markers);

      this.props.markers.forEach(marker => {
        return {
          marker: new google.maps.Marker({
            position: {
              lat: marker.latitude,
              lng: marker.longitude
            },

            title: marker.name,
            map: this.map
          })
        };
      });
    });
  }

  render() {
    return (
      <div>
        {this.props.title && (
          <ContentWrapper className="map-title">
            <Heading level={2} theme={Heading.themes.h2}>
              {this.props.title}
            </Heading>
          </ContentWrapper>
        )}
        <div
          className="map"
          id={this.props.htmlId}
          ref={div => (this.mapElement = div)}
        />
      </div>
    );
  }
}

export default Map;
