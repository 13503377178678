import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';
import IconLinkList from 'components/icon-link-list';
import Link from 'components/link';

const Downloads = ({ items, title }) => {
  return (
    <div className="downloads">
      <Heading color={Heading.colors.slate} theme={Heading.themes.h5}>
        {title}
      </Heading>
      <div className="downloads__list">
        <IconLinkList
          id="downloads" // NOTE: Is used only as 'key' by IconLinkList
          items={items.map(link => ({ icon: 'download', link }))}
        />
      </div>
    </div>
  );
};

Downloads.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  title: PropTypes.string
};

Downloads.defaultProps = {
  items: []
};

export default Downloads;
