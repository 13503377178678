import React from 'react';
import PropTypes from 'prop-types';

import focusChild from 'js/dom/focus-child';
import focusElement from 'js/dom/focus-element';
import focusRef from 'js/dom/focus-ref';
import useOnUnmount from 'hooks/use-on-unmount';

// NOTE: `focusSelector` can be used to override what element is focused when the tab trapper is activated.
const TabTrapper = ({ children, focusSelector, id, isActive }) => {
  const contentRef = React.useRef();
  const previouslyFocusedElement = React.useRef();

  React.useEffect(() => {
    if (typeof document !== 'undefined' && isActive) {
      previouslyFocusedElement.current = document.activeElement;
      focusSelector
        ? focusChild(contentRef, focusSelector)
        : focusRef(contentRef);
    } else {
      focusElement(previouslyFocusedElement.current);
    }
  }, [isActive]);

  useOnUnmount(() => focusElement(previouslyFocusedElement.current));

  const jumpToContent = () => focusRef(contentRef);

  const buttonStyle = {
    clip: 'rect(1px, 1px, 1px, 1px)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    width: '1px'
  };

  return (
    <React.Fragment>
      {isActive && <button onFocus={jumpToContent} style={buttonStyle} />}
      <div
        aria-modal="true"
        role="dialog"
        id={id}
        ref={contentRef}
        tabIndex={-1}
      >
        {children}
      </div>
      {isActive && <button onFocus={jumpToContent} style={buttonStyle} />}
    </React.Fragment>
  );
};

TabTrapper.propTypes = {
  children: PropTypes.node,
  focusSelector: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool
};

TabTrapper.propTypesMeta = 'exclude';

export default TabTrapper;
