import React, { useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  menu: 'menu'
};

const Search = ({
  endpoint,
  inputLabel,
  inputName,
  inputPlaceholder,
  inputValue,
  shouldFocusInput,
  submitLabel,
  theme,
  searchIsOpen
}) => {
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && shouldFocusInput) {
      inputRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    clearTimeout(timeoutRef.current);
    if (inputRef.current && searchIsOpen) {
      timeoutRef.current = setTimeout(() => inputRef.current.focus(), 300);
    }
  }, [searchIsOpen]);

  return (
    <form
      action={endpoint}
      className={cn('search', { [`search--${theme}`]: theme })}
    >
      <input
        className="search__input"
        aria-label={inputLabel}
        defaultValue={inputValue}
        type="text"
        name={inputName}
        placeholder={inputPlaceholder}
        ref={inputRef}
      />

      <button className="search__button" type="submit">
        <span className="search__button-text">{submitLabel}</span>
      </button>
    </form>
  );
};

Search.propTypes = {
  endpoint: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  inputValue: PropTypes.string,
  shouldFocusInput: PropTypes.bool,
  submitLabel: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  searchIsOpen: PropTypes.bool
};

Search.propTypesMeta = {
  shouldFocusInput: 'exclude',
  theme: 'exclude',
  searchIsOpen: 'exclude'
};

Search.themes = themes;

export default Search;
