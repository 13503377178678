/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import formatCount from '../../js/format-count';
import useCounter from 'hooks/use-counter';

import CarouselButton from 'components/carousel-button';
import Image from 'components/image';
import Heading from 'components/heading';
import Link from 'components/link';

const themes = {
  centered: 'centered',
  leftAligned: 'left-aligned',
  wide: 'wide',
  tall: 'tall'
};

const counterThemes = {
  circles: 'circles'
};

const Carousel = ({
  nextButtonText,
  previousButtonText,
  slides,
  themes,
  counterTheme,
  className
}) => {
  const [currentIndex, next, previous] = useCounter(0, 0, slides.length - 1);
  const [selectedSlideIndex] = React.useState(0);

  const refSlider = React.useRef();

  const hasContent = slides.some(slide =>
    slide.title || slide.text ? true : false
  );
  const hasNext = currentIndex < slides.length - 1;
  const hasPrevious = currentIndex > 0;

  const countText = React.useMemo(
    () => formatCount(currentIndex, slides.length),
    [currentIndex, slides.length]
  );

  var circleCounter = [];
  for (var i = 0; i < slides.length; i++) {
    circleCounter.push(
      <div
        key={i}
        className={cn('carousel__circle-counter', {
          'carousel__circle-counter--hasCurrent': i === currentIndex
        })}
      ></div>
    );
  }

  const onDotClick = index => {
    refSlider.current.slickGoTo(index);
  };

  return (
    <div
      className={cn(
        'carousel',
        className,
        {
          'carousel--with-pagination': counterTheme === 'circles'
        },
        themes.map(theme => `carousel--${theme}`)
      )}
    >
      <div className="carousel__picture-area">
        <React.Fragment>
          <ul
            className="carousel__track"
            style={{
              transform: `translate3d(${-currentIndex * 100}%, 0, 0)`
            }}
          >
            {slides.map((slide, index) => (
              <li
                className="carousel__slide"
                key={slide.src + slide.alt}
                style={{ left: `${index * 100}%` }}
              >
                {
                  <Image
                    theme={Image.themes.background}
                    src={slide.src}
                    alt={slide.alt}
                  />
                }
              </li>
            ))}
          </ul>
          <div className="carousel__buttons">
            <CarouselButton
              isActive={hasPrevious}
              onClick={previous}
              text={previousButtonText}
              theme={CarouselButton.themes.previous}
            />
            <CarouselButton
              isActive={hasNext}
              onClick={next}
              text={nextButtonText}
              theme={CarouselButton.themes.next}
            />
          </div>
        </React.Fragment>
      </div>

      <React.Fragment>
        {counterTheme === 'circles' ? (
          <div className="carousel__circle-counter-container">
            {slides.map((slide, index) => (
              <button
                className={cn('carousel__dot', {
                  'carousel__dot--active': index === selectedSlideIndex
                })}
                key={slide.src + slide.alt}
                onClick={e => {
                  e.preventDefault();
                  onDotClick(index);
                }}
                disabled={index === selectedSlideIndex}
              >
                {index} {selectedSlideIndex}
              </button>
            ))}
          </div>
        ) : (
          <div className="carousel__count-container">
            <div className="carousel__count">{countText}</div>
          </div>
        )}
      </React.Fragment>

      {hasContent && (
        <div className="carousel__content">
          {slides.map((slide, index) => (
            <div
              className="carousel__content-item"
              style={{
                position: `${index === currentIndex ? 'relative' : 'absolute'}`,
                visibility: `${index === currentIndex ? 'visible' : 'hidden'}`,
                left: `${index === currentIndex ? 'auto' : '-999px'}`
              }}
              key={slide.src + slide.alt}
            >
              {slide.title && (
                <div className="carousel__title">
                  <Heading theme={Heading.themes.h1}>
                    {slide.url ? (
                      <Link
                        animation="shrink"
                        url={slide.url}
                        text={slide.title}
                      />
                    ) : (
                      slide.title
                    )}
                  </Heading>
                </div>
              )}
              {slide.text && <div className="carousel__text">{slide.text}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  nextButtonText: PropTypes.string.isRequired,
  previousButtonText: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  ),
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes))),
  counterTheme: PropTypes.oneOf(Object.values(counterThemes)),
  className: PropTypes.string
};

Carousel.propTypesMeta = {
  themes: 'exclude',
  counterTheme: 'exclude',
  className: 'exclude'
};

Carousel.defaultProps = {
  slides: [],
  themes: []
};

Carousel.themes = themes;
Carousel.counterTheme = counterThemes;

export default Carousel;
