import React from 'react';
import PropTypes from 'prop-types';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import cn from 'classnames';
import ContentWrapper from 'components/content-wrapper';

const HousingProjectIframeBlock = ({ title, description, iframe, theme }) => {
  return (
    <div
      className={cn('housing-project-iframe-block', {
        [`housing-project-iframe-block--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader title={title} description={description} />
        {iframe && (
          <iframe
            src={iframe.src}
            className="housing-project-iframe-block__iframe"
            title={title}
          />
        )}
      </ContentWrapper>
    </div>
  );
};

HousingProjectIframeBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  iframe: PropTypes.shape({ src: PropTypes.string, title: PropTypes.string }),
  theme: PropTypes.string
};

export default HousingProjectIframeBlock;
