import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const HousingProjectRichText = ({ html, className }) => {
  return !html ? null : (
    <div
      className={cn('housing-project-rich-text', className)}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

HousingProjectRichText.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string
};

HousingProjectRichText.propTypesMeta = {
  className: 'exclude'
};

export default HousingProjectRichText;
