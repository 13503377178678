import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Heading from 'components/heading';
import Image from 'components/image';
import Icon from 'components/icon';

const ResidenceList = ({ items }) => {
  const { shouldShow } = React.useContext(FilterContext);

  return (
    <div className="residence-list">
      {items.map(
        ({ filterIds, id, image, subtitle, tag, title, url, isExternalLink }) =>
          !shouldShow(filterIds) ? null : (
            <a
              href={url}
              className="residence-list__item"
              key={id}
              target={isExternalLink ? '_blank' : null}
              rel={isExternalLink ? 'nofollow noopener noreferrer' : null}
            >
              <div className="residence-list__item-text">
                <Heading className="residence-list__item-title">
                  <span className="residence-list__item-title-span">
                    {title}
                  </span>
                  {isExternalLink && (
                    <Icon
                      className="residence-list__item-title-icon"
                      name="external-link-small"
                    ></Icon>
                  )}
                </Heading>

                <p className="residence-list__item-subtitle">{subtitle}</p>
              </div>
              <div className="residence-list__item-image-wrapper">
                {image && (
                  <Image
                    theme={Image.themes.background}
                    className="residence-list__item-image"
                    {...image}
                  />
                )}
                {tag && <div className="residence-list__item-tag">{tag}</div>}
              </div>
            </a>
          )
      )}
    </div>
  );
};

ResidenceList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      filterIds: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string.isRequired,
      image: PropTypes.exact(Image.propTypes),
      subtitle: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      isExternalLink: PropTypes.bool
    })
  )
};

ResidenceList.defaultProps = {
  items: []
};

export default ResidenceList;
