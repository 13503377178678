import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Heading from 'components/heading';
import PageListItem from './page-list-item';
import CallToAction from 'components/call-to-action';

const PageList = ({
  anchorId,
  items,
  title,
  callToAction,
  missingItemsText
}) => {
  const { shouldShow } = React.useContext(FilterContext);

  const listHasItems = () => {
    return items.some(item => shouldShow(item.filterIds));
  };

  return (
    <div className="page-list" id={anchorId}>
      <div className="page-list__content">
        {title && (
          <div className="page-list__heading">
            <Heading theme={Heading.themes.h2}>{title}</Heading>
          </div>
        )}
        {listHasItems() ? (
          <div className="page-list__items">
            {items.map(page => (
              <PageListItem key={page.title} {...page} />
            ))}
          </div>
        ) : (
          <p className="page-list__missing-text">{missingItemsText}</p>
        )}
        {callToAction && <CallToAction {...callToAction}></CallToAction>}
      </div>
    </div>
  );
};

PageList.propTypes = {
  anchorId: PropTypes.string,
  callToAction: PropTypes.exact(CallToAction.propTypes),
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(PageListItem.propTypes)),
  title: PropTypes.string,
  missingItemsText: PropTypes.string
};

PageList.defaultProps = {
  items: []
};

PageList.propTypesMeta = {
  className: 'exclude'
};

export default PageList;
