/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import HousingProjectInformationList from 'components/housing-project-information-list';
import HousingProjectStatus from 'components/housing-project-status';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import Link from 'components/link';
import Icon from 'components/icon';
import cn from 'classnames';

const baseThemes = {
  dark: 'dark',
  light: 'light'
};

const colorThemes = {
  _: '',
  green: 'green'
};

const HousingProjectInformation = ({
  title,
  location,
  informationListGroups,
  statusList,
  baseTheme,
  colorTheme
}) => {
  return (
    <div
      className={cn('housing-project-information', {
        [`housing-project-information--${baseTheme}`]: baseTheme
      })}
    >
      <ContentWrapper>
        <div className="housing-project-information__content">
          <div className="housing-project-information__key-information">
            {title && (
              <Heading className="housing-project-information__title" level={1}>
                {title}
              </Heading>
            )}
            <div className="housing-project-information__link-container">
              <Icon
                className="housing-project-information__link-icon"
                name="location"
              />
              <Link
                className="housing-project-information__link"
                animation="expand"
                {...location}
              />
            </div>
            {informationListGroups &&
              informationListGroups.length !== 0 &&
              informationListGroups.map((group, index) => (
                <HousingProjectInformationList
                  key={index}
                  {...group}
                  baseTheme={baseTheme}
                ></HousingProjectInformationList>
              ))}
          </div>
          <div className="housing-project-information__status">
            {statusList &&
              statusList.length !== 0 &&
              statusList.map(item => (
                <HousingProjectStatus
                  key={item.title}
                  {...item}
                  baseTheme={baseTheme}
                  colorTheme={colorTheme}
                ></HousingProjectStatus>
              ))}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectInformation.propTypes = {
  title: PropTypes.string,
  location: PropTypes.exact(Link.propTypes),
  informationListGroups: PropTypes.arrayOf(
    PropTypes.exact(HousingProjectInformationList.propTypes)
  ),
  statusList: PropTypes.arrayOf(
    PropTypes.exact(HousingProjectStatus.propTypes)
  ),
  baseTheme: PropTypes.oneOf(Object.values(baseThemes)),
  colorTheme: PropTypes.oneOf(Object.values(colorThemes))
};

HousingProjectInformation.propTypesMeta = {
  baseTheme: 'exclude',
  colorTheme: 'exclude'
};

export default HousingProjectInformation;
