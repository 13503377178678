import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import Link from 'components/link';
import RichText from 'components/rich-text';
import useGaTracking from 'hooks/use-ga-tracking';
import cn from 'classnames';

const FooterGroup = ({ items, text, title }) => (
  <div className={cn('footer-group')}>
    <Heading theme={Heading.themes.h5} color={Heading.colors.white}>
      {title}
    </Heading>

    {items.length !== 0 ? (
      <ul className="footer-group__items">
        {items.map(item => (
          <li className={cn('footer-group__item')} key={item.href + item.text}>
            <Link
              animation="shrink"
              onClick={
                item.url.includes('tel:') || item.url.includes('mailto:')
                  ? e => {
                      e.stopPropagation();
                      useGaTracking(
                        'Contact',
                        item.url.includes('tel:') ? 'Telephone' : 'Email',
                        item.text,
                        item.url
                      );
                    }
                  : null
              }
              color={Link.colors.yellow}
              fontTheme={Link.fontThemes.regular}
              {...item}
            />
          </li>
        ))}
      </ul>
    ) : null}
    {text && (
      <div className="footer-group__text">
        <RichText {...text} themes={[RichText.themes.footer]} />
      </div>
    )}
  </div>
);

FooterGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string
};

FooterGroup.defaultProps = {
  items: []
};

export default FooterGroup;
