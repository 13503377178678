import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const themes = {
  white: 'white',
  menu: 'menu'
};

const Logo = ({ theme }) => (
  <div
    className={cn('logo', {
      [`logo--${themes[theme]}`]: themes[theme]
    })}
  ></div>
);

Logo.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

Logo.defaultProps = {
  theme: themes.regular
};

Logo.propTypesMeta = 'exclude';

Logo.themes = themes;

export default Logo;
