import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components/link';
import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import Icon from 'components/icon';

const HousingProjectContactBlock = ({
  title,
  link,
  description,
  items,
  theme
}) => {
  return (
    <div
      className={cn('housing-project-contact-block', {
        [`housing-project-contact-block--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader
          title={title}
          link={link ? link : null}
          description={description ? description : null}
          theme={theme}
        />
        {items && items !== 0 && (
          <ul
            className={cn('housing-project-contact-block__items', {
              [`housing-prosject-contact-block__items housing-project-contact-block__items--${items.length}`]: items
            })}
          >
            {items.map((item, index) => (
              <li
                className={cn('housing-project-contact-block__item', {
                  [`housing-prosject-contact-block__item housing-project-contact-block__item--${items.length}`]: items
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={item.title + index}
              >
                {item.image && (
                  <Image
                    className="housing-project-contact-block__item-image"
                    {...item.image}
                  />
                )}
                {item.title && (
                  <div className="housing-project-contact-block__item-title">
                    <Heading level={3} theme={Heading.themes.h3}>
                      {item.title}
                    </Heading>
                    {item.subTitle && (
                      <div className="housing-project-contact-block__item-sub-title">
                        {item.subTitle}
                      </div>
                    )}
                  </div>
                )}
                <div className="housing-project-contact-block__item-contact-container">
                  {item.phone && (
                    <div className="housing-project-contact-block__item-contact">
                      <Icon
                        className="housing-project-contact-block__item-contact-icon"
                        name="phone"
                      />
                      <a
                        className="housing-project-contact-block__item-contact-link"
                        href={item.phone.url}
                      >
                        <span className="housing-project-contact-block__item-contact-link-text">
                          {item.phone.text}
                        </span>
                      </a>
                    </div>
                  )}
                  {item.email && (
                    <div className="housing-project-contact-block__item-contact">
                      <Icon
                        className="housing-project-contact-block__item-contact-icon housing-project-contact-block__item-contact-icon--mail"
                        name="mail"
                      />
                      <a
                        className="housing-project-contact-block__item-contact-link"
                        href={item.email.url}
                      >
                        <span className="housing-project-contact-block__item-contact-link-text">
                          {item.email.text}
                        </span>
                      </a>
                    </div>
                  )}
                  {item.logo && (
                    <div className="housing-project-contact-block__item-logo">
                      <Image {...item.logo} />
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </ContentWrapper>
    </div>
  );
};

HousingProjectContactBlock.propTypes = {
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  description: PropTypes.string,
  theme: PropTypes.string,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
      email: PropTypes.shape({ text: PropTypes.string, url: PropTypes.string }),
      phone: PropTypes.shape({ text: PropTypes.string, url: PropTypes.string }),
      logo: PropTypes.exact(Image.propTypes),
      image: PropTypes.exact(Image.propTypes)
    })
  )
};

HousingProjectContactBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectContactBlock;
