import React from 'react';
import PropTypes from 'prop-types';
import ImageWithCaption from 'components/image-with-caption';

const TwoImagesBlock = ({ image1, image2 }) => {
  return (
    <div className="two-images-block">
      {image1 && <ImageWithCaption {...image1} />}
      {image2 && <ImageWithCaption {...image2} />}
    </div>
  );
};

TwoImagesBlock.propTypes = {
  image1: PropTypes.exact(ImageWithCaption.propTypes),
  image2: PropTypes.exact(ImageWithCaption.propTypes)
};

export default TwoImagesBlock;
