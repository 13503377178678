import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Select from 'components/select';

const Filters = ({ options }) => {
  const { setFilter } = React.useContext(FilterContext);

  // NOTE: If a select has an initial value on mount, the context needs to be updated
  React.useEffect(() => {
    options.forEach(({ name, defaultSelectedId }) => {
      defaultSelectedId && setFilter(name, defaultSelectedId);
    });
  }, []);

  return (
    <div className="filters">
      {options.map(option => (
        <div className="filters__item" key={option.name}>
          <Select
            onChange={value => setFilter(option.name, value)}
            {...option}
          />
        </div>
      ))}
    </div>
  );
};

Filters.propTypes = {
  options: PropTypes.arrayOf(PropTypes.exact(Select.propTypes))
};

Filters.defaultProps = {
  options: []
};

export default Filters;
