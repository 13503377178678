import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import cn from 'classnames';

const NoItemsMessage = ({ heading, text, className }) => {
  return (
    <div className={cn('no-items-message', className)}>
      {heading && (
        <Heading level={3} className="no-items-message__heading">
          {heading}
        </Heading>
      )}
      {text && <div className="no-items-message__text">{text}</div>}
    </div>
  );
};

NoItemsMessage.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

NoItemsMessage.propTypesMeta = {
  className: 'exclude'
};

export default NoItemsMessage;
