import React from 'react';
import PropTypes from 'prop-types';

import { FilterContextProvider } from 'contexts/filter-context';

import Layout from 'components/layout';
import Support from 'components/support';
import InspoHeader from 'components/inspo-header';
import InspoArticleList from 'components/inspo-article-list';
import InspoArticleFilters from 'components/inspo-article-filters';
import ContentWrapper from 'components/content-wrapper';
import ComponentList from 'components/component-list';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';

const InspoListPage = ({
  layout,
  inspoHeader,
  articleList,
  filters,
  blocks
}) => {
  const header = <InspoHeader {...inspoHeader} />;

  return (
    <Layout
      supportThemes={[Support.themes.fullWidth]}
      fullWidthChildren={header}
      {...layout}
    >
      <FilterContextProvider>
        <ContentWrapper className="inspo-list-page__filter-wrapper">
          <InspoArticleFilters {...filters} />
        </ContentWrapper>

        <InspoArticleList {...articleList} />
      </FilterContextProvider>
      <ContentWrapper className="inspo-list-page__blocks">
        {blocks && (
          <ComponentList
            isInspoArticle
            {...blocks}
            {...mainContentDefaultOptions}
          />
        )}
      </ContentWrapper>
    </Layout>
  );
};

InspoListPage.propTypes = {
  inspoHeader: PropTypes.exact(InspoHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  articleList: PropTypes.exact(InspoArticleList.propTypes),
  filters: PropTypes.exact(InspoArticleFilters.propTypes),
  blocks: PropTypes.exact(ComponentList.propTypes)
};

InspoListPage.defaultProps = {
  filters: [],
  articleList: []
};

export default InspoListPage;
