import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';

import FrontpageHeader from 'components/frontpage-header';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import Layout from 'components/layout';
import RelatedPagesBlock from 'components/related-pages-block';
import Support from 'components/support';

const Frontpage = ({ blocks, header, layout, topLinks }) => {
  return (
    <Layout
      {...layout}
      supportThemes={[Support.themes.fullWidth]}
      fullWidthChildren={
        <FrontpageHeader {...header} colorTheme={layout.frontpageColorTheme}/>
      }
    >
      {topLinks && (
        <ContentWrapper className="frontpage__links">
          <RelatedPagesBlock {...topLinks} />
        </ContentWrapper>
      )}
      {blocks && (
        <ContentWrapper className="frontpage__blocks">
          <ComponentList {...blocks} {...mainContentDefaultOptions} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

Frontpage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  header: PropTypes.exact(FrontpageHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  topLinks: PropTypes.exact(RelatedPagesBlock.propTypes)
};

export default Frontpage;
