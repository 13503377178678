import React from 'react';
import PropTypes from 'prop-types';

import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import ArticleHeader from 'components/article-header';
import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import Heading from 'components/heading';
import Layout from 'components/layout';
import Link from 'components/link';
import RichText from 'components/rich-text';

const GlossaryPage = ({
  blocks,
  header,
  layout,
  sidebar,
  title,
  text,
  link
}) => {
  return (
    <Layout {...layout}>
      <ArticleHeader {...header} />

      <Columns
        className="glossary-page__content"
        themes={[Columns.themes.wideContent]}
      >
        <Columns.Sidebar>
          <ComponentList {...sidebar} {...sidebarDefaultOptions} />
        </Columns.Sidebar>

        <Columns.Main>
          <div className="glossary-page__main">
            <Heading theme={Heading.themes.h2}>{title}</Heading>
            <div className="glossary-page__text">
              <RichText {...text} />
            </div>
            {link && (
              <div className="glossary-page__link">
                <Link {...link} />
              </div>
            )}
          </div>
        </Columns.Main>
      </Columns>

      {blocks && (
        <ContentWrapper className="glossary-page__blocks">
          <ComponentList {...blocks} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

GlossaryPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes),
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Link.propTypes)
};

export default GlossaryPage;
