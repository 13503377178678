import useEvent from './use-event';

/** Executes the provided callback when a click event occurs above the provided ref */
const useClickAbove = (ref, callback = () => {}, excludedClass) => {
  useEvent('click', e => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const isClickAbove = e.clientY < rect.top;
      const isNotExcluded = !e.target.classList.contains(excludedClass);

      if (isClickAbove && isNotExcluded) {
        callback();
      }
    }
  });
};

export default useClickAbove;
