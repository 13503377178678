const useIntersectionObserver = (ref, setIsVisible) => {
  if ('IntersectionObserver' in window) {
    const itemObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          itemObserver.disconnect();
        }
      },
      { root: null, rootMargin: '0px', threshold: 0.05 }
    );

    if (ref.current) {
      itemObserver.observe(ref.current);
    }
  }
};

export default useIntersectionObserver;
