import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Plx from 'react-plx';

import Link from 'components/link';
import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import HousingProjectBlockHeader from 'components/housing-project-block-header';
import HousingProjectInformationList from 'components/housing-project-information-list';

const HousingProjectListWithIconsBlock = ({
  title,
  link,
  description,
  items,
  theme,
  id
}) => {
  const plxData = [
    {
      start: `.housing-project-list-with-icons-block--${id}`,
      duration: '80vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  const plxData2 = [
    {
      start: `.housing-project-list-with-icons-block--${id}`,
      duration: '80vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 150,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  const plxData3 = [
    {
      start: `.housing-project-list-with-icons-block--${id}`,
      duration: '80vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 200,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];
  const plxData4 = [
    {
      start: `.housing-project-list-with-icons-block--${id}`,
      duration: '80vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 250,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];
  return (
    <div
      className={cn('housing-project-list-with-icons-block', {
        [`housing-project-list-with-icons-block--${theme}`]: theme,
        [`housing-project-list-with-icons-block housing-project-list-with-icons-block--${id}`]: id
      })}
    >
      <ContentWrapper>
        <HousingProjectBlockHeader
          title={title}
          link={link ? link : null}
          description={description ? description : null}
          theme={theme}
        />
        {items && items !== 0 && (
          <ul
            className={cn('housing-project-list-with-icons-block__items', {
              [`housing-prosject-list-block__items housing-project-list-with-icons-block__items--${items.length}`]: items
            })}
          >
            {items.map((item, index) => {
              let myIndex = index + 1;
              return (
                <li
                  className={cn('housing-project-list-with-icons-block__item', {
                    [`housing-prosject-list-block__item housing-project-list-with-icons-block__item--${myIndex}`]: items
                  })}
                  key={item.title}
                >
                  <Plx
                    className="housing-project-list-with-icons-block__plx-wrapper"
                    parallaxData={
                      myIndex % 4 !== 0 &&
                      myIndex % 2 !== 0 &&
                      myIndex % 3 !== 0
                        ? plxData
                        : myIndex % 4 === 0
                        ? plxData4
                        : myIndex % 3 === 0
                        ? plxData3
                        : plxData2
                    }
                  >
                    {item.image && (
                      <div className="housing-project-list-with-icons-block__item-image">
                        <Image {...item.image} />
                      </div>
                    )}

                    {item.title || item.text ? (
                      <div className="housing-project-list-with-icons-block__item-text-content">
                        {item.title && (
                          <div className="housing-project-list-with-icons-block__item-title">
                            <Heading level={3} theme={Heading.themes.h2}>
                              {item.title}
                            </Heading>
                          </div>
                        )}
                        {item.text && (
                          <div className="housing-project-list-with-icons-block__item-text">
                            {item.text}
                          </div>
                        )}
                      </div>
                    ) : null}
                    {item.link && (
                      <Link
                        className="housing-project-list-with-icons-block__item-link"
                        animation="shrink"
                        {...item.link}
                      />
                    )}
                  </Plx>
                </li>
              );
            })}
          </ul>
        )}
      </ContentWrapper>
    </div>
  );
};

HousingProjectListWithIconsBlock.propTypes = {
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  description: PropTypes.string,
  theme: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      table: PropTypes.exact(HousingProjectInformationList.propTypes),
      link: PropTypes.exact(Link.propTypes),
      image: PropTypes.exact(Image.propTypes)
    })
  )
};

HousingProjectListWithIconsBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectListWithIconsBlock;
