import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Expander from 'components/expander';
import RichText from 'components/rich-text';
import Heading from 'components/heading';

const themes = {
  narrow: 'narrow'
};

const ExpanderList = ({ title, content, hideText, showText, theme }) => {
  return (
    <div
      className={cn('expander-list', { [`expander-list--${theme}`]: theme })}
    >
      {title && (
        <Heading className="expander-list__title" theme={Heading.themes.h2}>
          {title}
        </Heading>
      )}
      {content.map(({ richText, title, text }) => (
        <Expander
          key={title}
          hideText={hideText}
          showText={showText}
          title={title}
        >
          <div className="expander-list__item-text">
            {text && <p>{text}</p>}
            {richText && (
              <RichText themes={[RichText.themes.expanderList]} {...richText} />
            )}
          </div>
        </Expander>
      ))}
    </div>
  );
};

ExpanderList.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.exact({
      richText: PropTypes.exact(RichText.propTypes),
      title: PropTypes.string,
      text: PropTypes.string
    })
  ),
  hideText: PropTypes.string.isRequired,
  showText: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes))
};

ExpanderList.propTypesMeta = {
  theme: 'exclude'
};

ExpanderList.defaultProps = {
  content: []
};

ExpanderList.themes = themes;

export default ExpanderList;
