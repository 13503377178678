import React from 'react';
import PropTypes from 'prop-types';

import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import RichText from 'components/rich-text';
import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import ComponentListItem from 'components/component-list-item';

const InspoRichTextWithSidebar = ({ text, sidebar }) => {
  return (
    <div className="inspo-rich-text-with-sidebar">
      <Columns className="inspo-rich-text-with-sidebar__content">
        <Columns.Main>
          <RichText {...text} />
        </Columns.Main>

        <Columns.Sidebar>
          {sidebar && (
            <ComponentList
              {...sidebar}
              {...sidebarDefaultOptions}
              wrapperElementProps={{
                LinkList: {
                  themes: [
                    ComponentListItem.themes.smallMargin,
                    ComponentListItem.themes.visibleOnScroll
                  ]
                },
                InspoPromoBlock: {
                  themes: [ComponentListItem.themes.visibleOnScroll]
                },
                InspoRelatedArticles: {
                  themes: [ComponentListItem.themes.visibleOnScroll]
                }
              }}
            />
          )}
        </Columns.Sidebar>
      </Columns>
    </div>
  );
};

InspoRichTextWithSidebar.propTypes = {
  sidebar: PropTypes.exact(ComponentList.propTypes),
  text: PropTypes.exact(RichText.propTypes)
};

export default InspoRichTextWithSidebar;
