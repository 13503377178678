import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  link: 'link',
  button: 'button'
};

const Radio = ({ label, name, onChange, value, theme, defaultChecked }) => {
  const id = `radio-${name}-${value}`;

  return (
    <div className={cn('radio', { [`radio--${theme}`]: theme })}>
      <input
        id={id}
        name={name}
        onChange={() => onChange(value)}
        value={value}
        type="radio"
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id}>
        <span className="radio-label">{label}</span>
      </label>
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
  defaultChecked: PropTypes.bool
};

Radio.propTypesMeta = {
  theme: 'exclude'
};

Radio.defaultProps = {
  onChange: () => {},
  defaultChecked: false
};

Radio.themes = themes;

export default Radio;
