import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import HousingProjectImageGalleryItem from 'components/housing-project-image-gallery-item';

const HousingProjectImageGallery = ({ links, items }) => {
  return (
    <div className="housing-project-image-gallery">
      {links && links !== 0 && (
        <div className="housing-project-image-gallery__links">
          {links.map(link => (
            <Link
              className="housing-project-image-gallery__link"
              animation="shrink"
              key={link.text}
              {...link}
            ></Link>
          ))}
        </div>
      )}
      {items && items !== 0 && (
        <div className="housing-project-image-gallery__items">
          {items.map(item => (
            <HousingProjectImageGalleryItem
              key={item.title + item.id}
              {...item}
            ></HousingProjectImageGalleryItem>
          ))}
        </div>
      )}
    </div>
  );
};

HousingProjectImageGallery.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  items: PropTypes.arrayOf(
    PropTypes.exact(HousingProjectImageGalleryItem.propTypes)
  )
};

export default HousingProjectImageGallery;
