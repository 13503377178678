import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import Button from 'components/button';
import ContentWrapper from 'components/content-wrapper';
import cn from 'classnames';

const HousingProjectDocuments = ({ items, theme }) => {
  return (
    <div
      className={cn('housing-project-documents', {
        [`housing-project-documents--${theme}`]: theme
      })}
    >
      {items && items !== 0 && (
        <ul className="housing-project-documents__items">
          {items.map(item => (
            <li className="housing-project-documents__item" key={item.title}>
              <ContentWrapper>
                <div className="housing-project-documents__item-content">
                  {item.title && (
                    <Heading
                      level={2}
                      theme={Heading.themes.h3PortalBold}
                      className="housing-project-documents__item-title"
                    >
                      {item.title}
                    </Heading>
                  )}
                  {item.text && (
                    <div className="housing-project-documents__item-text">
                      {item.text}
                    </div>
                  )}
                  {item.documents && item.documents !== 0 && (
                    <ul className="housing-project-documents__documents">
                      {item.documents.map(document => (
                        <li
                          className="housing-project-documents__document"
                          key={document.text}
                        >
                          <Button
                            {...document}
                            housingProjectBaseTheme={
                              theme === 'green' || theme === 'dark'
                                ? 'dark'
                                : 'light'
                            }
                            housingProjectColorTheme={
                              theme === 'green' ? theme : null
                            }
                            housingProjectButtonTheme="primary"
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </ContentWrapper>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

HousingProjectDocuments.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      documents: PropTypes.arrayOf(PropTypes.exact(Button.propTypes))
    })
  ),
  theme: PropTypes.string
};

HousingProjectDocuments.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectDocuments;
