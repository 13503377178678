import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const Metadata = ({ className, items, id }) => {
  return (
    <dl className={cn('metadata', className)} key={id}>
      {items.map(({ label, text }) => (
        <React.Fragment key={label + text}>
          <dt>{label}</dt>
          <dd>{text}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

Metadata.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      text: PropTypes.string
    })
  )
};

Metadata.propTypesMeta = {
  className: 'exclude'
};

Metadata.defaultProps = {
  items: []
};

export default Metadata;
