import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  black: 'black',
  white: 'white',
  red: 'red',
  slate: 'slate',
  greenDark: 'green-dark'
};

const themes = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h3Portal: 'h3-portal',
  h3PortalBold: 'h3-portal-bold',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  inherit: 'inherit'
};

const Heading = ({ children, className, level, color, theme }) => {
  if (!children) {
    // NOTE: This prevents empty heading tags, which is an accessibility problem
    return null;
  }

  return React.createElement(
    `h${level}`,
    {
      className: cn(
        'heading',
        {
          [`heading--color-${color}`]: color,
          [`heading--${theme}`]: theme
        },
        className
      )
    },
    children
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
  color: PropTypes.oneOf(Object.values(colors)),
  theme: PropTypes.oneOf(Object.values(themes))
};

Heading.defaultProps = {
  level: 2
};

Heading.propTypesMeta = {
  level: 'exclude',
  color: 'exclude',
  theme: 'exclude'
};

Heading.colors = colors;
Heading.themes = themes;

export default Heading;
