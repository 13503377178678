import React from 'react';
import PropTypes from 'prop-types';

import FilterContext from 'contexts/filter-context';

import Image from 'components/image';
import Heading from 'components/heading';
import Video from 'components/video';

const InspoArticleList = ({ articles }) => {
  const { shouldShow } = React.useContext(FilterContext);

  return (
    <ul className="inspo-article-list">
      {articles.map(article =>
        !shouldShow(article.filterIds) ? null : (
          <li
            key={article.title + article.id}
            className="inspo-article-list__item"
          >
            <a className="inspo-article-list__link" href={article.url}>
              {article.video && (
                <div className="inspo-article-list__video-container">
                  <Video
                    containerClassName="video__container--in-inspo-article-list"
                    videoClassName="video--in-inspo-article-list"
                    {...article.video}
                  ></Video>
                </div>
              )}
              {article.image && !article.video && (
                <div className="inspo-article-list__image-container">
                  <Image theme={Image.themes.background} {...article.image} />
                </div>
              )}

              {article.tags && (
                <div className="inspo-article-list__tags">
                  {article.tags.map(tag => (
                    <span className="inspo-article-list__tag">{tag}</span>
                  ))}
                </div>
              )}
              <Heading className="inspo-article-list__title">
                <span className="inspo-article-list__title-span">
                  {article.title}
                </span>
              </Heading>
              {article.ingress && (
                <div className="inspo-article-list__ingress">
                  {article.ingress}
                </div>
              )}
            </a>
          </li>
        )
      )}
    </ul>
  );
};

InspoArticleList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      filterIds: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.exact(Image.propTypes),
      ingress: PropTypes.string,
      url: PropTypes.string,
      video: PropTypes.exact(Video.propTypes),
      id: PropTypes.string
    })
  )
};

InspoArticleList.defaultProps = {
  articles: []
};

export default InspoArticleList;
