import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';
import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import ArticleHeader from 'components/article-header';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import Columns from 'components/columns';
import RichText from 'components/rich-text';
import Layout from 'components/layout';

const ArticlePage = ({ blocks, header, layout, sidebar, text }) => {
  return (
    <Layout {...layout}>
      <ArticleHeader {...header}></ArticleHeader>
      {(text || sidebar) && (
        <Columns className="article-page__text">
          <Columns.Main>
            <RichText {...text}></RichText>
          </Columns.Main>

          <Columns.Sidebar>
            {sidebar && (
              <ComponentList {...sidebar} {...sidebarDefaultOptions} />
            )}
          </Columns.Sidebar>
        </Columns>
      )}

      {blocks && (
        <ContentWrapper className="article-page__blocks">
          <ComponentList {...blocks} {...mainContentDefaultOptions} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

ArticlePage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes),
  text: PropTypes.exact(RichText.propTypes)
};

export default ArticlePage;
