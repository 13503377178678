import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const themes = {
  green: 'green',
  white: 'white',
  pink: 'pink',
  lightPink: 'light-pink',
  transparent: 'transparent'
};

const InspoAnimatedTitle = ({ text, isMounted, theme, tag }) => {
  return (
    <div className="inspo-animated-title">
      <div
        className={cn('inspo-animated-title__title-container', {
          [`inspo-animated-title__title-container--${theme}`]: theme,
          'inspo-animated-title__title-container--is-mounted': isMounted
        })}
      >
        {tag && (
          <div
            className={cn('inspo-animated-title__tag', {
              'inspo-animated-title__tag--is-mounted': isMounted
            })}
          >
            {tag}
          </div>
        )}
        <span
          className={cn('inspo-animated-title__title-background', {
            [`inspo-animated-title__title-background--${theme}`]: theme,
            'inspo-animated-title__title-background--is-mounted': isMounted
          })}
        >
          <span
            className={cn('inspo-animated-title__title-folder', {
              [`inspo-animated-title__title-folder--${theme}`]: theme,
              'inspo-animated-title__title-folder--is-mounted': isMounted
            })}
          >
            <span
              className={cn('inspo-animated-title__title-text', {
                [`inspo-animated-title__title-text--${theme}`]: theme,
                'inspo-animated-title__title-text--is-mounted': isMounted
              })}
            >
              {text}
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

InspoAnimatedTitle.propTypes = {
  text: PropTypes.string,
  isMounted: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes)),
  tag: PropTypes.string
};

InspoAnimatedTitle.defaultProps = {
  theme: themes.green
};

InspoAnimatedTitle.propTypesMeta = {
  isMounted: 'exclude'
};

export default InspoAnimatedTitle;
