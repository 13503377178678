import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext({
  mainMenuOpen: true,
  globalWarningBlockHeigth: 0,
  setIsMainMenuOpen: () => {},
  setGlobalWarningBlockHeight: () => {}
});

export const MenuContextProvider = ({ children }) => {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [shouldHideMainMenu, setShouldHideMainMenu] = useState(false);
  const [globalWarningBlockHeigth, setGlobalWarningBlockHeight] = useState(
    '0px'
  );

  const changeGlobalWarningBlockHeigth = newHeight => {
    setGlobalWarningBlockHeight(`${newHeight}px`);
  };

  return (
    <Context.Provider
      value={{
        isMainMenuOpen,
        shouldHideMainMenu,
        globalWarningBlockHeigth,
        setIsMainMenuOpen,
        setShouldHideMainMenu,
        changeGlobalWarningBlockHeigth
      }}
    >
      {children}
    </Context.Provider>
  );
};

MenuContextProvider.propTypes = {
  children: PropTypes.node
};

MenuContextProvider.propTypesMeta = 'exclude';

export default Context;
