import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  bigScreenOnly: 'big-screen-only',
  smallScreenOnly: 'small-screen-only'
};

const ContentWrapper = ({ className, children, theme }) => (
  <div
    className={cn(
      'content-wrapper',
      { [`content-wrapper--${theme}`]: theme },
      className
    )}
  >
    {children}
  </div>
);

ContentWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.oneOf(Object.values(themes))
};

ContentWrapper.propTypesMeta = 'exclude';

ContentWrapper.themes = themes;

export default ContentWrapper;
