import React from 'react';
import PropTypes from 'prop-types';

import sidebarDefaultOptions from 'js/block-area/sidebar-default-options';

import ArticleHeader from 'components/article-header';
import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import ExpanderList from 'components/expander-list';
import Layout from 'components/layout';

const FaqPage = ({ blocks, content, header, layout, sidebar }) => {
  return (
    <Layout {...layout}>
      <ArticleHeader {...header} />

      <Columns
        className="faq-page__content"
        themes={[Columns.themes.wideContent]}
      >
        <Columns.Sidebar>
          <ComponentList {...sidebar} {...sidebarDefaultOptions} />
        </Columns.Sidebar>

        <Columns.Main>
          <ExpanderList {...content} />
        </Columns.Main>
      </Columns>

      {blocks && (
        <ContentWrapper className="faq-page__blocks">
          <ComponentList {...blocks} />
        </ContentWrapper>
      )}
    </Layout>
  );
};

FaqPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  content: PropTypes.exact(ExpanderList.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes)
};

export default FaqPage;
