import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from 'components/content-wrapper';
import Carousel from 'components/carousel';
import Heading from 'components/heading';
import Link from 'components/link';
import Text from 'components/text';

const CarouselBlock = ({ carousel, text, title, url }) => {
  return (
    <div className="carousel-block">
      <ContentWrapper className="carousel-block__content">
        {title && (
          <div className="carousel-block__title">
            <Heading theme={Heading.themes.h1}>
              {url ? <Link animation="shrink" url={url} text={title} /> : title}
            </Heading>
          </div>
        )}
        {text && (
          <div className="carousel-block__text">
            <Text theme={Text.themes.ingress}>{text}</Text>
          </div>
        )}
      </ContentWrapper>

      <div className="carousel-block__carousel">
        {carousel && <Carousel themes={[Carousel.themes.wide]} {...carousel} />}
      </div>
    </div>
  );
};

CarouselBlock.propTypes = {
  carousel: PropTypes.exact(Carousel.propTypes),
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};

export default CarouselBlock;
