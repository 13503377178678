import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import supportedComponents from './supported-components';
import { error } from 'js/log';
import ReactComponent from 'components/react-component';
import useIntersectionObserver from 'hooks/use-intersection-observer';

const themes = {
  smallMargin: 'small-margin',
  noMargin: 'no-margin',
  noPadding: 'no-padding',
  wide: 'wide',
  visibleOnScroll: 'visible-on-scroll',
  excludeFromVisibleOnScroll: 'exclude-from-visible-on-scroll',
  greyBackground: 'grey-background',
  whiteBackground: 'white-background',
  beigeBackground: 'beige-background',
  blackBackground: 'black-background',
  transparentBackground: 'transparent-background'
};

const ComponentListItem = ({
  additionalComponentProps,
  componentNotSupportedText,
  data,
  id,
  key,
  name,
  wrapperElementProps,
  isInspoArticle
}) => {
  const Component = supportedComponents()[name];
  const [isVisible, setIsVisible] = React.useState(false);

  if (!Component) {
    error(`😵 ComponentList does not support rendering of '${name}'.`);
    return componentNotSupportedText ? (
      <div className="component-list-item--fallback">
        {componentNotSupportedText} <i>{name}</i>
      </div>
    ) : null;
  }

  if (!data || !Object.keys(data).length) {
    return null;
  }

  const additionalProps = additionalComponentProps[name];

  if (process.env.NODE_ENV !== 'production') {
    // NOTE: wrapping in 'props' in order to do strict validation with 'exact' (top level propTypes are loosely validated)
    PropTypes.checkPropTypes(
      { props: PropTypes.exact(Component.propTypes) },
      { props: { ...data, ...additionalProps } },
      'prop',
      name
    );
  }

  const { themes = [] } = wrapperElementProps[name] || {};

  const ref = React.createRef();

  React.useEffect(() => {
    useIntersectionObserver(ref, setIsVisible);
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        'component-list-item',
        { 'component-list-item--is-inspo-article': isInspoArticle },
        {
          'component-list-item--visible-on-scroll--is-visible':
            isVisible && themes.includes('visible-on-scroll')
        },

        themes.map(theme => `component-list-item--${theme}`)
      )}
      id={id}
      key={key}
    >
      <Component {...data} {...additionalProps} />
    </div>
  );
};

ComponentListItem.propTypes = {
  additionalComponentProps: PropTypes.object,
  componentNotSupportedText: PropTypes.string,
  data: PropTypes.shape(ReactComponent.propTypes).isRequired, // Intentional use of 'shape' because validation of 'componentData' is handled by the components themselves
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  wrapperElementProps: PropTypes.objectOf(
    PropTypes.exact({
      themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
    })
  ),
  isInspoArticle: PropTypes.bool
};

ComponentListItem.propTypesMeta = {
  additionalComponentProps: 'exclude',
  componentNotSupportedText: 'exclude',
  wrapperElementProps: 'exclude',
  isInspoArticle: 'exclude'
};

ComponentListItem.defaultProps = {
  wrapperElementProps: {}
};

ComponentListItem.themes = themes;

export default ComponentListItem;
