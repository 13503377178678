import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Icon from 'components/icon';
import paginationGroups from './pagination-groups';
import cn from 'classnames';

const Pagination = ({
  links,
  prevPageUrl,
  prevPageText,
  nextPageUrl,
  nextPageText
}) => {
  let linkGroups = React.useMemo(() => paginationGroups(links), [links]);
  const activeIndex = links.findIndex(link => link.active);

  return (
    <div className="pagination">
      <div className="pagination__links">
        <a
          aria-label={prevPageText}
          href={prevPageUrl}
          className="pagination__prev"
        >
          <Icon
            className={cn('pagination__icon', {
              'pagination__icon--active': activeIndex !== 0
            })}
            name="arrow-button"
          ></Icon>
        </a>
        {linkGroups.map((linkGroup, index) => (
          <div
            className="pagination__link-group"
            key={linkGroup.map(link => link.text).join('')}
          >
            {index !== 0 && <div>...</div>}
            {linkGroup.map(link => (
              <div key={link.url + link.text} className="pagination__link">
                {link.active ? (
                  <div>{link.text}</div>
                ) : (
                  <Link
                    fontTheme={Link.fontThemes.light}
                    animation={Link.animation.shrink}
                    {...link}
                  ></Link>
                )}
              </div>
            ))}
          </div>
        ))}

        <a
          aria-label={nextPageText}
          href={nextPageUrl}
          className="pagination__next"
        >
          <Icon
            className={cn('pagination__icon', {
              'pagination__icon--active': activeIndex !== links.length - 1
            })}
            name="arrow-button"
          ></Icon>
        </a>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  prevPageUrl: PropTypes.string,
  prevPageText: PropTypes.string,
  nextPageUrl: PropTypes.string,
  nextPageText: PropTypes.string
};

Pagination.defaultProps = {
  links: [],
  active: false
};

export default Pagination;
