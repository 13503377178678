/* eslint-disable react/no-multi-comp */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import formatCount from '../../js/format-count';

import RelatedArticlesCarouselSlide from './related-articles-carousel-slide';
import RelatedArticlesCarouselWrapper from './related-articles-carousel-wrapper';
import Heading from 'components/heading';
import Icon from 'components/icon';
import Link from 'components/link';

const themes = {
  dark: 'dark',
  light: 'light'
};

const RelatedArticlesCarousel = ({
                                   title,
                                   link,
                                   previousButtonText,
                                   nextButtonText,
                                   slides,
                                   theme,
                                   inHousingProjectArticle = false
                                 }) => {
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isSlidesVisible, setIsSlidesVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentSwiperId = 'swiper' + title.replace(/[^a-zA-Z0-9]/g, '');

  const params = {
    initialSlide: 0,
    centeredSlides: false,
    spaceBetween: 24,
    slidesPerView: 'auto',
    breakpoints: {
      800: {
        spaceBetween: 40
      }
    },
    navigation: {
      nextEl: '.swiper-navbutton-next' + currentSwiperId,
      prevEl: '.swiper-navbutton-prev' + currentSwiperId
    }
  };

  const titleRef = useRef();
  const slidesRef = useRef();

  const handleNext = () => {
    setCurrentIndex(prev => {
      if (prev + 1 < slides.length) {
        return prev + 1;
      }
      return prev;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex(prev => {
      if (prev - 1 >= 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const countText = useMemo(() => formatCount(currentIndex, slides.length), [
    currentIndex,
    slides.length
  ]);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const titleObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsTitleVisible(true);
            titleObserver.disconnect();
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.4 }
      );

      const slidesObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsSlidesVisible(true);
            slidesObserver.disconnect();
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.4 }
      );

      if (titleRef.current) {
        titleObserver.observe(titleRef.current);
      }
      if (slidesRef.current) {
        slidesObserver.observe(slidesRef.current);
      }
    }
  }, []);

  return (
    <RelatedArticlesCarouselWrapper renderWrapper={inHousingProjectArticle}>
      <div
        className={cn('related-articles-carousel', {
          [`related-articles-carousel--${theme}`]: theme
        })}
      >
        <div className="related-articles-carousel__header">
          {title && (
            <div
              ref={titleRef}
              className={cn('related-articles-carousel__title-container', {
                'related-articles-carousel__title-container--visible': isTitleVisible
              })}
            >
              <Heading
                className="related-articles-carousel__title"
                level={2}
                theme={Heading.themes.h1}
              >
                {title}
              </Heading>
            </div>
          )}
          {link && (
            <a
              className="related-articles-carousel__link"
              href={link.url}
            >
              <span className="related-articles-carousel__link-text">
                {link.text}
              </span>
              <Icon
                className="related-articles-carousel__link-icon"
                name="arrow-thin"
              />
            </a>
          )}
        </div>
        <div
          ref={slidesRef}
          className={cn('related-articles-carousel__slides-container', {
            'related-articles-carousel__slides-container--visible': isSlidesVisible
          })}
        >
          <div
            id={currentSwiperId}
            className="related-articles-carousel__slides"
          >
            <Swiper
              modules={[Navigation]}
              {...params}
              onTransitionEnd={swiper => {
                if (swiper.isEnd) {
                  setCurrentIndex(slides.length - 1);
                } else if (swiper.isBeginning) {
                  setCurrentIndex(0);
                } else {
                  swiper && setCurrentIndex(swiper.activeIndex);
                }
              }}
            >
              {slides &&
                slides.map((item, index) => {
                  const itemIndex = index;
                  return (
                    <SwiperSlide key={item.title + item.tag + itemIndex}>
                      <RelatedArticlesCarouselSlide {...item} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <div className="related-articles-carousel__navigation-wrapper">
              <button
                aria-label={previousButtonText}
                className={
                  'related-articles-carousel__navigation-button swiper-button-prev swiper-navbutton-prev' +
                  currentSwiperId
                }
                onClick={handlePrevious}
              >
                <div className="related-articles-carousel__navigation-button__icon-container">
                  <Icon
                    className="related-articles-carousel__navigation-button__icon related-articles-carousel__navigation-button__icon__prev"
                    name="chevron-big-right"
                  />
                </div>
              </button>
              <div className="related-articles-carousel__navigation-counter">
                {countText}
              </div>{' '}
              <button
                aria-label={nextButtonText}
                className={
                  'related-articles-carousel__navigation-button swiper-button-next swiper-navbutton-next' +
                  currentSwiperId
                }
                onClick={handleNext}
              >
                <div className="related-articles-carousel__navigation-button__icon-container">
                  <Icon
                    className="related-articles-carousel__navigation-button__icon related-articles-carousel__navigation-button__icon__next"
                    name="chevron-big-right"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </RelatedArticlesCarouselWrapper>
  );
};

RelatedArticlesCarousel.propTypes = {
  inHousingProjectArticle: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  nextButtonText: PropTypes.string.isRequired,
  previousButtonText: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.exact(RelatedArticlesCarouselSlide.propTypes)
  ),
  theme: PropTypes.oneOf(Object.values(themes))
};

RelatedArticlesCarousel.propTypesMeta = {
  theme: 'exclude'
};

RelatedArticlesCarousel.themes = themes;

export default RelatedArticlesCarousel;
