import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import RichText from 'components/rich-text';

const themes = {
  red: 'red',
  yellow: 'yellow',
  green: 'green'
};

const ColoredFrame = ({ title, text, theme }) => {
  return (
    <div
      className={cn('colored-frame', { [`colored-frame--${theme}`]: theme })}
    >
      <div className="colored-frame__title">
        <Heading theme={Heading.themes.h2}>{title}</Heading>
      </div>
      <RichText {...text} />
    </div>
  );
};

ColoredFrame.propTypes = {
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  theme: PropTypes.oneOf(Object.values(themes))
};

ColoredFrame.defaultProps = {
  themes: themes.yellow
};

ColoredFrame.themes = themes;

export default ColoredFrame;
