import React from 'react';
import ImageWithTextItem from 'components/image-with-text-item';
import ContentWrapper from 'components/content-wrapper';

import PropTypes from 'prop-types';

const ImageWithTextList = ({ listItems }) => {
  return (
    <ContentWrapper className="image-with-text-list-wrapper">
      <div className="image-with-text-list">
        {listItems &&
          listItems.length > 0 &&
          listItems.map(element => <ImageWithTextItem {...element} />)}
      </div>
    </ContentWrapper>
  );
};

ImageWithTextList.propTypes = {
  listItems: PropTypes.arrayOf(ImageWithTextItem.propTypes)
};

export default ImageWithTextList;
