import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import LinkList from 'components/link-list';
import cn from 'classnames';

const LinkListBlock = ({ groups, title }) => {
  return (
    <div className="link-list-block">
      {title && (
        <div className="link-list-block__heading-container">
          <Heading
            className="link-list-block__heading"
            level={2}
            theme={Heading.themes.h1}
          >
            {title}
          </Heading>
        </div>
      )}

      <div className="link-list-block__groups">
        {groups.map(group => (
          <div
            className={cn('link-list-block__group', {
              [`link-list-block__group--without-heading`]: !group.title
            })}
            key={group.title}
          >
            <LinkList {...group}></LinkList>
          </div>
        ))}
      </div>
    </div>
  );
};

LinkListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.exact(LinkList.propTypes))
};

LinkListBlock.defaultProps = {
  groups: []
};

export default LinkListBlock;
