import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Video from 'components/video';
import cn from 'classnames';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import Plx from 'react-plx';
import RichText from 'components/rich-text';

const backgroundColors = {
  greenDark: 'green-dark',
  beigeLight: 'beige-light',
  pink: 'pink',
  pinkLight: 'pink-light'
};

const InspoFullWidthMediaBlock = ({
  title,
  intro,
  introColor = 'green-dark',
  titleColor = 'pink-light',
  image,
  video,
  id
}) => {
  const plxData = [
    {
      start: `.inspo-full-width-media-block__wrapper--${id}`,
      duration: '150vh',
      startOffset: '100vh',
      properties: [
        {
          startValue: 0,
          endValue: -1000,
          property: 'translateY'
        }
      ]
    }
  ];
  return (
    <div
      className={cn(
        `inspo-full-width-media-block__wrapper inspo-full-width-media-block__wrapper--${id}`,
        { 'inspo-full-width-media-block__wrapper--has-video': video }
      )}
    >
      <div className="inspo-full-width-media-block">
        {video && (
          <Video
            {...video}
            shouldLoop
            containerClassName="video-container--in-inspo-full-width-media-block"
            videoClassName="video--in-inspo-full-width-media-block"
          />
        )}
        {image && !video && (
          <Image
            theme={Image.themes.background}
            {...image}
            className="inspo-full-width-media-block__image"
          />
        )}
        <div className="child-wrapper">
          <ContentWrapper className="inspo-full-width-media-block__content">
            <Plx parallaxData={plxData}>
              {title && (
                <div className="inspo-full-width-media-block__heading">
                  <Heading level={2} theme={Heading.themes.h1}>
                    <span
                      className={cn(
                        'inspo-full-width-media-block__heading-text',
                        {
                          [`inspo-full-width-media-block__heading-text--background-${titleColor}`]: titleColor
                        }
                      )}
                    >
                      {title}
                    </span>
                  </Heading>
                </div>
              )}

              {intro && (
                <div
                  className={cn('inspo-full-width-media-block__intro', {
                    [`inspo-full-width-media-block__intro--background-${introColor}`]: introColor
                  })}
                >
                  <RichText
                    themes={[RichText.themes.inspoFullWidthMediaIntroBlock]}
                    {...intro}
                  ></RichText>
                </div>
              )}
            </Plx>
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

InspoFullWidthMediaBlock.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.exact(RichText.propTypes),
  id: PropTypes.string,
  introColor: PropTypes.oneOf(Object.values(backgroundColors)),
  titleColor: PropTypes.oneOf(Object.values(backgroundColors)),
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes)
};

InspoFullWidthMediaBlock.colors = backgroundColors;

export default InspoFullWidthMediaBlock;
