import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';
import ResidenceList from 'components/residence-list';

const ResidenceListBlock = ({ residences, title }) => {
  return (
    <div className="residence-list-block">
      <Heading
        className="residence-list-block__title"
        theme={Heading.themes.h2}
      >
        {title}
      </Heading>
      <div className="residence-list-block__residences">
        <ResidenceList {...residences} />
      </div>
    </div>
  );
};

ResidenceListBlock.propTypes = {
  residences: PropTypes.exact(ResidenceList.propTypes),
  title: PropTypes.string
};

export default ResidenceListBlock;
