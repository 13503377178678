import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components/link';

const themes = {
  regular: 'regular',
  button: 'button'
};

const colors = {
  green: 'green',
  yellow: 'yellow',
  red: 'red'
};

const alignments = {
  left: 'left',
  right: 'right'
};

const CallToAction = ({
  url,
  theme,
  color,
  alignment,
  linkText,
  text,
  shouldOpenInNewTab
}) => (
  <div className={cn('call-to-action', `call-to-action--${alignment}`)}>
    <div className="call-to-action__content">
      {text && <div className="call-to-action__text">{text}</div>}
      {theme === 'regular' ? (
        <div className="call-to-action__link--regular">
          <Link
            animation="expand"
            text={linkText}
            url={url}
            color={Link.colors.black}
            fontTheme={Link.fontThemes.uppercaseCondensed}
            showIcon={true}
            shouldOpenInNewTab={shouldOpenInNewTab}
          ></Link>
        </div>
      ) : (
        <div
          className={cn('call-to-action__link', {
            [`call-to-action__link--${theme}`]: theme
          })}
        >
          <Link
            animation="expand"
            text={linkText}
            url={url}
            theme={theme === 'button' && Link.themes.buttonSmall}
            shouldOpenInNewTab={shouldOpenInNewTab}
            className={cn({
              [`call-to-action__link--${color}`]: color
            })}
          ></Link>
        </div>
      )}
    </div>
  </div>
);

CallToAction.defaultProps = {
  theme: themes.regular,
  color: colors.green,
  alignment: alignments.left
};

CallToAction.propTypes = {
  linkText: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
  color: PropTypes.oneOf(Object.values(colors)),
  alignment: PropTypes.oneOf(Object.values(alignments)),
  shouldOpenInNewTab: PropTypes.bool
};

CallToAction.alignments = alignments;

export default CallToAction;
