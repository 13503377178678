import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import MenuContext from 'contexts/menu-context';

import RichText from 'components/rich-text';
import Button from 'components/button';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

const GLOBAL_WARNING_SESSION_STORAGE_ID = 'global-warning-closed';

const GlobalWarningBlock = ({
  textAsHtml,
  closeButtonText,
  isPlaceholder,
  isOpen,
  handleIsOpen
}) => {
  const c = useContext(MenuContext);

  const blockRef = useRef(null);

  const handleOnClose = () => {
    if (!isRunningOnClient) return;
    if (!sessionStorage) return;

    sessionStorage.setItem(GLOBAL_WARNING_SESSION_STORAGE_ID, 'viewed');
    handleIsOpen && handleIsOpen(false);

    if (blockRef.current) {
      c.changeGlobalWarningBlockHeigth(0);
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const { height } = entries[0].contentRect;
      c.changeGlobalWarningBlockHeigth(height);
    });

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, [c]);

  useEffect(() => {
    if (!isRunningOnClient) return;
    if (!sessionStorage) return;

    const isViewed = sessionStorage.getItem(GLOBAL_WARNING_SESSION_STORAGE_ID);

    if (!isViewed) {
      handleIsOpen && handleIsOpen(true);

      if (blockRef.current) {
        c.changeGlobalWarningBlockHeigth(blockRef.current.height);
      }
    }
  }, []);

  return (
    <div
      ref={blockRef}
      className={cn('global-warning-block', {
        'global-warning-block--active': isOpen,
        'global-warning-block--placeholder': isPlaceholder
      })}
    >
      <div className="global-warning-block__content-wrapper">
        <div className="global-warning-block__text-wrapper">
          <RichText html={textAsHtml} />
        </div>
        {closeButtonText && (
          <div className="global-warning-block__button-wrapper">
            <Button
              alignment="center"
              theme="warningbanner"
              text={closeButtonText}
              iconName="x"
              onClick={handleOnClose}
              iconIsAfterText
            />
          </div>
        )}
      </div>
    </div>
  );
};

GlobalWarningBlock.propTypes = {
  textAsHtml: PropTypes.string,
  closeButtonText: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleIsOpen: PropTypes.func
};

GlobalWarningBlock.propTypesMeta = {
  isOpen: 'exclude',
  isPlaceholder: 'exclude',
  handleIsOpen: 'exclude'
};

export default GlobalWarningBlock;
