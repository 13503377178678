import React from 'react';
import PropTypes from 'prop-types';

const CreditsBlock = ({ text }) => {
  return (
    <div className="credits-block">
      <div className="credits-block__text">{text}</div>
    </div>
  );
};

CreditsBlock.propTypes = {
  text: PropTypes.string
};

export default CreditsBlock;
