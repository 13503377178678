function paginationGroups(list = []) {
  const activeIndex = list.findIndex(link => link.active);

  if (list.length <= 7) {
    return [list];
  } else if (activeIndex < 3) {
    return [list.slice(0, 3), list.slice(-1)];
  } else if (activeIndex >= list.length - 3) {
    return [list.slice(0, 1), list.slice(-3)];
  } else {
    return [
      list.slice(0, 1),
      list.slice(activeIndex - 1, activeIndex + 2),
      list.slice(-1)
    ];
  }
}

export default paginationGroups;
