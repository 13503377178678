import React from 'react';
import PropTypes from 'prop-types';
import CallToAction from 'components/call-to-action';

const EventPromoBlock = ({
  day,
  month,
  text,
  url,
  buttonText,
  callToAction
}) => {
  return (
    <div className="event-promo-block">
      <div className="event-promo-block__cta">
        <CallToAction {...callToAction} />
      </div>
      <a href={url} className="event-promo-block__content">
        <div className="event-promo-block__date">
          <div className="event-promo-block__day">{day}</div>
          <div className="event-promo-block__month">{month}</div>
        </div>
        <div className="event-promo-block__text-container">
          <div className="event-promo-block__text">{text}</div>
          <div className="event-promo-block__cta-button-container">
            <span className="event-promo-block__cta-button">{buttonText}</span>
          </div>
        </div>
      </a>
    </div>
  );
};

EventPromoBlock.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  callToAction: PropTypes.exact(CallToAction.propTypes)
};

export default EventPromoBlock;
