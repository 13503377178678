import React from 'react';
import PropTypes from 'prop-types';

const RelatedArticlesCarouselWrapper = ({ children, renderWrapper }) => {
  return (
    <>
      {renderWrapper ? (
        <div className="related-articles-carousel__housing-project-article-wrapper">
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

RelatedArticlesCarouselWrapper.propTypes = {
  renderWrapper: PropTypes.bool,
  children: PropTypes.node
};

RelatedArticlesCarouselWrapper.propTypesMeta = 'exclude';

export default RelatedArticlesCarouselWrapper;
