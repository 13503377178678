import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

const HeaderToggleButton = ({
  className,
  onClick,
  isActive,
  activeIconName,
  nonActiveIconName,
  label,
  text
}) => {
  return (
    <div
      className={cn(
        'header-toggle-button',
        {
          'header-toggle-button--is-open': isActive
        },
        className
      )}
    >
      <button
        onClick={onClick}
        aria-label={label}
        aria-expanded={isActive}
        className="header-toggle-button__button"
      >
        {isActive ? (
          <Icon name={activeIconName} className="header-toggle-button__icon" />
        ) : (
          <Icon
            name={nonActiveIconName}
            className="header-toggle-button__icon"
          />
        )}
        <span className="header-toggle-button__text">{text}</span>
      </button>
    </div>
  );
};

HeaderToggleButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  activeIconName: PropTypes.string,
  nonActiveIconName: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string
};

HeaderToggleButton.propTypesMeta = {
  className: 'exclude',
  onClick: 'exclude',
  isActive: 'exclude',
  activeIconName: 'exclude',
  nonActiveIconName: 'exclude'
};

export default HeaderToggleButton;
