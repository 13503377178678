import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useIsMounted from 'hooks/use-is-mounted';
import useEvent from 'hooks/use-event';
import useToggle from 'hooks/use-toggle';
import useClickOutside from 'hooks/use-click-outside';

import Icon from 'components/icon';

// NOTE: This is a naive implementation of 'lodash/get'. Replace it if you have 'lodash' as a dependency
const get = (object, key, fallback) => {
  try {
    return object[key];
  } catch (_) {
    return fallback;
  }
};

// NOTE: Since an option can't have `null` or `false` as a value (the text label of the option will be used instead), the "null" choice is represented using a single space. A single space is serialized to an empty value when submitting a form (which is what we want). Comparing against `magicNullValue` also lets the component return `null` from its `onChange` callback when selecting the placeholder option.
const magicNullValue = ' ';

const Select = ({
  defaultSelectedId,
  disabled,
  id,
  label,
  name,
  onChange,
  options,
  placeholder
}) => {
  const [isOpen, toggle, close] = useToggle(false);

  const [hasTouch, setHasTouch] = React.useState(false);
  useEvent('touchstart', () => setHasTouch(true));

  const fakeSelectRef = React.useRef();
  useClickOutside(fakeSelectRef, close);

  const [value, setValue] = React.useState(defaultSelectedId);

  const isMounted = useIsMounted();
  React.useEffect(() => {
    // NOTE: The `onChange` callback indicates the user action of selecting, so it's not called for the initial render
    isMounted && onChange(value);
  }, [value]);

  const handleChange = value => {
    setValue(value === magicNullValue ? null : value);
    close();
  };

  const valueLabel = React.useMemo(
    () =>
      get(
        options.find(o => o.id === value),
        'text',
        placeholder
      ),
    [options, value]
  );

  return (
    <div
      className={cn('select', {
        'select--active': isOpen,
        'select--has-touch': hasTouch,
        'select--is-mounted': isMounted,
        'select--is-disabled': disabled
      })}
    >
      <select
        aria-label={label}
        disabled={disabled}
        name={name}
        id={id}
        onChange={e => handleChange(e.target.value)}
        value={value || ''}
      >
        {placeholder && <option value={magicNullValue}>{placeholder}</option>}
        {options.map(({ id, text }) => (
          <option key={id} value={id}>
            {text}
          </option>
        ))}
      </select>

      <div className="select__fake">
        <div
          aria-hidden="true"
          className="select__element"
          onClick={disabled ? () => {} : toggle}
          ref={fakeSelectRef}
        >
          {valueLabel}
          <Icon
            className={cn('select__icon', { 'select__icon--active': isOpen })}
            name="chevron-down"
          />
        </div>
        {isOpen && (
          <ul className="select__dropdown">
            {placeholder && value !== null && (
              <li
                aria-hidden="true"
                className="select__option"
                onClick={() => handleChange(magicNullValue)}
              >
                {placeholder}
              </li>
            )}
            {options
              .filter(({ id }) => id !== value)
              .map(({ id, text }) => (
                <li
                  aria-hidden="true"
                  className="select__option"
                  key={id}
                  onClick={() => handleChange(id)}
                >
                  <span>{text}</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  defaultSelectedId: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string
};

Select.propTypesMeta = {
  disabled: 'exclude'
};

Select.defaultProps = {
  defaultSelectedId: null,
  onChange: () => {},
  options: []
};

export default Select;
