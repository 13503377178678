import React from 'react';
import PropTypes from 'prop-types';
import HousingProjectRichText from 'components/housing-project-rich-text';
import ContentWrapper from 'components/content-wrapper';
import Button from 'components/button';
import cn from 'classnames';

const themes = {
  dark: 'dark',
  green: 'green',
  light: 'light'
};

const HousingProjectRichTextBlock = ({ html, theme, link }) => {
  return !html ? null : (
    <div className="housing-project-rich-text-block">
      <ContentWrapper>
        <div className="housing-project-rich-text-block__content">
          <HousingProjectRichText
            html={html}
            className={cn('housing-project-rich-text', {
              [`housing-project-rich-text--${theme}`]: theme
            })}
          />
          {link && (
            <div className="housing-project-rich-text-block__link">
              <Button
                housingProjectBaseTheme={
                  theme === 'green' || theme === 'dark' ? 'dark' : 'light'
                }
                housingProjectColorTheme={theme === 'green' ? theme : null}
                housingProjectButtonTheme="primary"
                {...link}
              />
            </div>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectRichTextBlock.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themes)),
  link: PropTypes.exact(Button.propTypes)
};

HousingProjectRichTextBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectRichTextBlock;
