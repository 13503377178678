import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from 'components/content-wrapper';
import Carousel from 'components/carousel';
import Image from 'components/image';
import Link from 'components/link';

const ImageAndCarousel = ({ carousel, image, imageText, text, url }) => {
  return (
    <div className="image-and-carousel">
      <div className="image-and-carousel__left">
        <div className="image-and-carousel__image">
          {image && <Image theme={Image.themes.background} {...image} />}
        </div>
        {imageText && (
          <p className="image-and-carousel__image-text">{imageText}</p>
        )}
      </div>

      <div className="image-and-carousel__right">
        {carousel && (
          <Carousel
            themes={[Carousel.themes.wide, Carousel.themes.leftAligned]}
            {...carousel}
          />
        )}

        <div className="image-and-carousel__text">
          <ContentWrapper theme={ContentWrapper.themes.smallScreenOnly}>
            {url ? (
              <Link animation="shrink" text={text} url={url} />
            ) : (
              <p>{text}</p>
            )}
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

ImageAndCarousel.propTypes = {
  carousel: PropTypes.exact(Carousel.propTypes),
  image: PropTypes.exact(Image.propTypes),
  imageText: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
};

export default ImageAndCarousel;
