import React from 'react';
import PropTypes from 'prop-types';

import mainContentDefaultOptions from 'js/block-area/main-content-default-options';
import { FilterContextProvider } from 'contexts/filter-context';

import Columns from 'components/columns';
import ComponentList from 'components/component-list';
import ContentWrapper from 'components/content-wrapper';
import Filters from 'components/filters';
import Heading from 'components/heading';
import ResidenceList from 'components/residence-list';
import Layout from 'components/layout';
import RichText from 'components/rich-text';

const ResidenceListPage = ({
  blocks,
  filters,
  layout,
  residences,
  title,
  ingress
}) => {
  return (
    <Layout {...layout}>
      <div className="residence-list-page">
        <FilterContextProvider>
          <Columns>
            <Columns.Main>
              <div className="residence-list-page__header">
                <Heading
                  className="residence-list-page__title"
                  level={1}
                  theme={Heading.themes.h1}
                >
                  {title}
                </Heading>
                <RichText
                  {...ingress}
                  className="residence-list-page__ingress"
                />
              </div>
            </Columns.Main>
          </Columns>
          <ContentWrapper>
            <div className="residence-list-page__filters">
              <Filters {...filters} />
            </div>
            {residences && (
              <div className="residence-list-page__residences">
                <ResidenceList {...residences} />
              </div>
            )}
          </ContentWrapper>
        </FilterContextProvider>

        {blocks && (
          <ContentWrapper className="residence-list-page__blocks">
            <ComponentList {...blocks} {...mainContentDefaultOptions} />
          </ContentWrapper>
        )}
      </div>
    </Layout>
  );
};

ResidenceListPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  filters: PropTypes.exact(Filters.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  residences: PropTypes.exact(ResidenceList.propTypes),
  title: PropTypes.string.isRequired,
  ingress: PropTypes.exact(RichText.propTypes)
};

ResidenceListPage.defaultProps = {
  residences: []
};

export default ResidenceListPage;
