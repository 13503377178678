/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentWrapper from 'components/content-wrapper';

const themes = {
  compactMobile: 'compact-mobile',
  wideContent: 'wide-content'
};

const Columns = ({ className, children, themes }) => (
  <ContentWrapper
    className={cn(
      'columns',
      themes.map(theme => `columns--${theme}`),
      className
    )}
  >
    {children}
  </ContentWrapper>
);

Columns.Main = ({ className, children }) => (
  <div className={cn('columns__main', className)}>{children}</div>
);

Columns.Sidebar = ({ className, children }) => (
  <div className={cn('columns__sidebar', className)}>{children}</div>
);

Columns.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
};

Columns.propTypesMeta = 'exclude';

Columns.defaultProps = {
  themes: []
};

Columns.themes = themes;

export default Columns;
