import React from 'react';
import PropTypes from 'prop-types';

const SkipNavLink = ({ targetId, text }) => {
  return (
    <a className="skip-nav-link" href={`#${targetId}`}>
      {text}
    </a>
  );
};

SkipNavLink.propTypes = {
  targetId: PropTypes.string,
  text: PropTypes.string
};

SkipNavLink.propTypesMeta = 'exclude';

export default SkipNavLink;
