import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  green: '--green'
};

const FullWidthQuoteBlock = ({ text, author, theme }) => {
  return (
    <div
      className={cn('full-width-quote-block', {
        [`${theme}`]: theme
      })}
    >
      <blockquote className="full-width-quote-block__content">
        {text && <p className="full-width-quote-block__text">{text}</p>}
        {author && (
          <footer className="full-width-quote-block__author">{author}</footer>
        )}
      </blockquote>
    </div>
  );
};

FullWidthQuoteBlock.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

FullWidthQuoteBlock.propTypesMeta = {
  theme: 'exclude'
};

FullWidthQuoteBlock.themes = themes;

export default FullWidthQuoteBlock;
