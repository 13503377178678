import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Heading from 'components/heading';
import Button from 'components/button';
import ContentWrapper from 'components/content-wrapper';
import RichText from 'components/rich-text';

import cn from 'classnames';

const alignments = {
  left: 'left',
  right: 'right'
};

const HousingProjectImageWithText = ({
  title,
  richText,
  link,
  image,
  theme,
  alignment
}) => {
  return (
    <div
      className={cn('housing-project-image-with-text', {
        [`housing-project-image-with-text--${theme}`]: theme,
        [`housing-project-image-with-text--${alignment}`]: alignment
      })}
    >
      <ContentWrapper>
        <div className="housing-project-image-with-text__content">
          <div className="housing-project-image-with-text__text-container">
            {title && (
              <Heading
                level={2}
                theme={Heading.themes.h3PortalBold}
                className="housing-project-image-with-text__title"
              >
                {title}
              </Heading>
            )}
            {richText && (
              <div className="housing-project-image-with-text__text">
                <RichText
                  themes={[RichText.themes.housingProjectImageWithText]}
                  {...richText}
                />
              </div>
            )}
            {link && (
              <div className="housing-project-image-with-text__link">
                <Button
                  housingProjectBaseTheme={
                    theme === 'green' || theme === 'dark' ? 'dark' : 'light'
                  }
                  housingProjectColorTheme={theme === 'green' ? theme : null}
                  housingProjectButtonTheme="primary"
                  {...link}
                />
              </div>
            )}
          </div>

          {image && (
            <div className="housing-project-image-with-text__image-container">
              <div className="housing-project-image-with-text__image">
                <Image theme={Image.themes.background} {...image} />
              </div>
            </div>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectImageWithText.propTypes = {
  title: PropTypes.string,
  richText: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Button.propTypes),
  image: PropTypes.exact(Image.propTypes),
  theme: PropTypes.string,
  alignment: PropTypes.oneOf(Object.values(alignments))
};

HousingProjectImageWithText.propTypesMeta = {
  theme: 'exclude'
};

HousingProjectImageWithText.alignments = alignments;

export default HousingProjectImageWithText;
