/* eslint-disable react/no-multi-comp */
import React from 'react';
import cn from 'classnames';

import FilterContext from 'contexts/filter-context';
import Heading from 'components/heading';
import Image from 'components/image';
import PropTypes from 'prop-types';

const tagColors = {
  red: 'red',
  yellow: 'yellow',
  green: 'green'
};

const PromotedPerk = ({
  filterIds,
  image,
  tagColor,
  tagText,
  title,
  url,
  isSlide
}) => {
  const { shouldShow } = React.useContext(FilterContext);

  const getElement = () => {
    return (
      <a
        href={url}
        key={title}
        className={cn('promoted-perk', { 'swiper-slide': isSlide })}
      >
        <div
          className={cn('promoted-perk__image-container', {
            'promoted-perk__image-container--border': Boolean(image)
          })}
        >
          {tagText && (
            <div
              className={cn('promoted-perk__tag', {
                [`promoted-perk__tag--${tagColor}`]: tagColors
              })}
            >
              {tagText}
            </div>
          )}
          {image && (
            <div className="promoted-perk__image">
              <Image theme={Image.themes.background} {...image}></Image>
            </div>
          )}
        </div>

        <div className="promoted-perk__heading">
          <Heading color={Heading.colors.red}>
            <span className="promoted-perk__heading-span">{title}</span>
          </Heading>
        </div>
      </a>
    );
  };

  return !shouldShow(filterIds) ? null : isSlide ? (
    getElement()
  ) : (
    <li>{getElement()}</li>
  );
};

PromotedPerk.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.exact(Image.propTypes),
  tagText: PropTypes.string,
  tagColor: PropTypes.oneOf(Object.values(tagColors)),
  title: PropTypes.string,
  url: PropTypes.string,
  isSlide: PropTypes.bool
};

PromotedPerk.defaultProps = {
  filterIds: []
};

PromotedPerk.propTypesMeta = {
  isSlide: 'exclude'
};

export default PromotedPerk;
